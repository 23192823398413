import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { NavLink } from 'react-router-dom';
import bookingImage from '../../img/undraw_booking.svg';
import Footer from '../common/Footer';
import Header from '../common/Header';
import Pricing from './Pricing';
import headerImage from '../../img/header.jpeg'

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  main: {
    backgroundColor: '#fafafa'
  },
  titleBlock: {
    padding: theme.spacing(3, 3),
    backgroundColor: 'rgba(255,255,255,0.8)'
  },
  heroContent: {
    backgroundSize: 'cover',
    backgroundImage: `url(${headerImage})`,
    padding: theme.spacing(12, 0, 12),
  },
  fancyImage: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
    textAlign: 'center'
  },
  image: {
    width: '100%',
    maxWidth: 700,
    padding: 40,
    textAlign: 'center'
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    padding: theme.spacing(6)
  },
}));

export default function Landing() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <Header></Header>
      <main className={classes.main}>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container maxWidth="sm" className={classes.titleBlock}>
            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
              Agendr
            </Typography>
            <Typography variant="h5" align="center" color="textSecondary" paragraph>
              Beheer gemakkelijk evenementen voor verschillende ruimtes.
            </Typography>
            <div className={classes.heroButtons}>
              <Grid container spacing={2} justify="center">
                <Grid item>
                  <NavLink to="/register">
                    <Button variant="contained" color="primary">
                      Maak een account
                    </Button>
                  </NavLink>
                </Grid>
                <Grid item>
                  <NavLink to="/dashboard">
                    <Button variant="outlined" color="primary">
                      Inloggen met een bestaand account
                    </Button>
                  </NavLink>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>
        <Container className={classes.cardGrid} maxWidth="md">
          {/* End hero unit */}
          <Pricing></Pricing>
        </Container>
      </main>

      <Container className={classes.fancyImage}>
        <img alt="" src={bookingImage} className={classes.image}></img>
      </Container>
      {/* Footer */}
      <Footer></Footer>
      {/* End footer */}
    </React.Fragment>
  );
}