import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, OutlinedInput, Paper, Select, Switch, Tab, Tabs, TextField } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/core/styles';
import { InlineDatePicker, InlineTimePicker } from "material-ui-pickers";
import React from 'react';
import ReactDOM from 'react-dom';
import { roundToQuarter } from '../../util/momentUtil';
import Constants from '../../Constants';

const styles = {
    textField: {
        maxWidth: 400,
        width: '100%'
    },
    formControl: {
        width: '100%',
        marginTop: 10
    }
};

class SelectDateTime extends React.Component {

    state = {
        labelWidth: 0,
        currentTab: 0
    }

    componentDidMount() {
        this.setState({
            labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
            labelWidth2: ReactDOM.findDOMNode(this.InputLabelRef2).offsetWidth,
        });
    }


    onEventUpdate = (event) => {
        this.props.onEventUpdate && this.props.onEventUpdate(event)
    }

    handleChange = name => evt => {
        let { event = {} } = this.props;

        event[name] = evt.target.value;

        this.onEventUpdate(event);
    }

    handleChecked = name => evt => {
        let { event = {} } = this.props;

        event[name] = evt.target.checked;

        if (name === "morning" || name === "noon" || name === "evening") {
            if (event.morning) {
                event.timeStart = event.timeStart.clone().hours(6).minutes(0);
                event.timeEnd = event.timeStart.clone().hours(12).minutes(0);
            }

            if (event.noon) {
                !event.morning && (event.timeStart = event.timeStart.clone().hours(12).minutes(0));
                event.timeEnd = event.timeStart.clone().hours(18).minutes(0);
            }

            if (event.evening) {
                !event.noon && (event.timeStart = event.timeStart.clone().hours(18).minutes(0));
                event.timeEnd = event.timeStart.clone().hours(23).minutes(59);
            }
        }

        this.onEventUpdate(event);
    };


    handleDateChange = name => (date) => {
        date = roundToQuarter(date)

        let { event = {} } = this.props;

        if (name === "timeStart" || name === "timeEnd") {
            event.morning = false;
            event.noon = false;
            event.evening = false;
        }

        event[name] = date;

        this.onEventUpdate(event);
    };

    handleTabChange = (event, currentTab) => {
        this.setState({
            currentTab
        })
    }

    static getErrors(event) {
        const errors = {};

        if (event.description.length === 0) {
            errors.description = 'Dit veld is verplicht';
        }

        if (event.date.length === 0) {
            errors.date = 'Dit veld is verplicht';
        }

        if (!event.allDay && event.timeStart.length === 0) {
            errors.timeStart = 'Geen correcte tijd';
        }

        if (!event.allDay && event.timeEnd.length === 0) {
            errors.timeEnd = 'Geen correcte tijd';
        }

        if (event.timeStart.unix() > event.timeEnd.unix()) {
            errors.timeEnd = 'De tijd is voor de de starttijd';
        }

        return errors;
    }

    render() {
        const { classes } = this.props;

        const { event = {} } = this.props;

        const { showErrors } = this.props;

        const { currentTab } = this.state;

        const { morning, noon, evening } = event;

        let errors = {};
        if (showErrors) {
            errors = SelectDateTime.getErrors(event);
        }

        return (
            <div>
                <div>
                    <TextField
                        id="outlined-name"
                        label="Omschrijving (verplicht)"
                        error={errors.description !== undefined}
                        helperText={errors.description || ''}
                        value={event.description}
                        onChange={this.handleChange('description')}
                        className={classes.textField}
                        margin="normal"
                        variant="outlined"
                    />
                </div>

                <div>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel
                            ref={ref => {
                                this.InputLabelRef = ref;
                            }}
                            htmlFor="outlined-age-native-simple"
                        >
                            Type organisatie
                </InputLabel>
                        <Select
                            native
                            value={event.typeOrganization}
                            onChange={this.handleChange('typeOrganization')}
                            className={classes.textField}
                            input={
                                <OutlinedInput
                                    name="age"
                                    labelWidth={this.state.labelWidth}
                                    id="outlined-age-native-simple"
                                />
                            }
                        >
                            <option value="Bedrijf">Bedrijf</option>
                            <option value="School">School</option>
                            <option value="Sportvereniging">Sportvereniging</option>
                            <option value="Maatschappelijke organisatie">Maatschappelijke organisatie</option>
                            <option value="Overig">Overig</option>
                        </Select>
                    </FormControl>
                </div>
                <br />
                {this.props.event.typeOrganization === "Sportvereniging" && <>
                    <div>
                        <FormControl variant="outlined" className={classes.formControl}>

                            <InputLabel
                                ref={ref => {
                                    this.InputLabelRef2 = ref;
                                }}
                                htmlFor="outlined-age-native-simple2"
                            >
                                Organiserende vereniging (optioneel)
                </InputLabel>
                            <Select
                                native
                                value={event.club}
                                onChange={this.handleChange('club')}
                                className={classes.textField}
                                input={
                                    <OutlinedInput
                                        name="age"
                                        labelWidth={this.state.labelWidth2}
                                        id="outlined-age-native-simple2"
                                    />
                                }
                            >
                                {Constants.clubs.map(club => <option value={club} key={club}>{club}</option>)}
                            </Select>
                        </FormControl>
                    </div>
                    <br />
                </>}

                <div>
                    <InlineDatePicker
                        label="Datum (verplicht)"
                        variant="outlined"
                        error={errors.date !== undefined ? true : undefined}
                        helperText={errors.date || ''}
                        value={event.date}
                        onChange={this.handleDateChange('date')}
                    >
                    </InlineDatePicker>

                    <br />

                    <Paper className={classes.root}>
                        <Tabs
                            value={currentTab}
                            onChange={this.handleTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                            centered
                        >
                            <Tab label="Specifieke tijd" />
                            <Tab label="Per dagdeel" />
                        </Tabs>
                    </Paper>

                    {currentTab === 0 ? <div>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={event.allDay}
                                    onChange={this.handleChecked('allDay')}
                                    value="checkedA"
                                />
                            }
                            label="Duurt de hele dag"
                        />
                        <br /><br />

                        {!event.allDay ? <div>
                            <InlineTimePicker
                                ampm={false}
                                keyboard
                                mask={[/\d/, /\d/, ":", /\d/, /\d/]}
                                variant="outlined"
                                label="Van"
                                error={errors.timeStart !== undefined ? true : undefined}
                                helperText={errors.timeStart || ''}
                                onChange={this.handleDateChange('timeStart')}
                                value={event.timeStart}
                                TextFieldComponent={(props) => {
                                    return <TextField {...props}></TextField>
                                }
                                }
                            />
                            <br /><br />
                            <InlineTimePicker
                                ampm={false}
                                keyboard
                                mask={[/\d/, /\d/, ":", /\d/, /\d/]}
                                variant="outlined"
                                label="Tot"
                                error={errors.timeEnd !== undefined ? true : undefined}
                                helperText={errors.timeEnd || ''}
                                onChange={this.handleDateChange('timeEnd')}
                                value={event.timeEnd}
                            />
                            <br /><br />
                        </div> : ''}
                    </div> : <div>

                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormLabel component="legend">Kies de dagedelen</FormLabel>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox checked={morning} disabled={evening && !noon} onChange={this.handleChecked('morning')} value="morning" />}
                                        label="Ochtend 06:00 - 12:00"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox checked={noon} disabled={morning && evening} onChange={this.handleChecked('noon')} value="noon" />}
                                        label="Middag 12:00 - 18:00"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={evening} disabled={morning && !noon} onChange={this.handleChecked('evening')} value="evening" />
                                        }
                                        label="Avond 06:00 - 00:00"
                                    />
                                </FormGroup>
                                <FormHelperText>De dagdelen moeten elkaar opvolgen</FormHelperText>
                                <br />
                            </FormControl>

                        </div>}

                </div>
            </div>
        );
    }
}

export default withStyles(styles)(SelectDateTime);
