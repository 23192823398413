import { Modal, Typography, withStyles, Card, CardContent, CardActions, Button } from '@material-ui/core';
import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/nl';
import React, { Component } from 'react';
import BigCalendar from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Constants from '../../Constants';
import { fetchAndFormatAllReservations } from '../../util/reservationUtil';
import './Calendar.css';
import Reservation from './Reservation';
import FilterIcon from '@material-ui/icons/FilterList';
import DashboardContext from '../admin/dashboard/DashboardContext'

moment.tz.setDefault("Europe/Amsterdam");

const eventStyleGetter = (event, start, end, isSelected) => {
  return {
    style: {
      backgroundColor: Constants.clubColorsCalendar[event.club] || 'grey'
    }
  };
};

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = theme => ({
  paper: {
    position: 'absolute',
    maxWidth: theme.spacing(50),
    width: '97%',
    backgroundColor: theme.palette.background.paper,
    outline: 'none',
  },
});

const backdropStyle = {
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0
};

const messages = {
  allDay: 'Hele dag',
  previous: '<',
  next: '>',
  today: 'Vandaag',
  month: 'Maand',
  week: 'Week',
  day: 'Dag',
  agenda: 'Agenda',
  date: 'Datum',
  time: 'Tijd',
  event: 'Evenement',
  showMore: total => `+${total} meer`,
};

// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.
const localizer = BigCalendar.momentLocalizer(moment) // or globalizeLocalizer

const RoomButton = (props) => {
  const { name, selected } = props;

  const style = {
    background: 'white',
    border: 'solid black 1px',
    fontSize: '12px',
    margin: 2,
    padding: 5,
    cursor: 'pointer'
  }

  if (selected) {
    style.background = "#44ab69";
    style.border = 'solid #44ab69 1px';
    style.color = 'white';
  }

  return <button {...props} style={style}>
    {name}
  </button>
}

class Calendar extends Component {

  static contextType = DashboardContext;

  constructor() {
    super();

    this.state = {
      reservationModalIsOpen: false,
      filterModalIsOpen: false,
      events: null,
      selectedEvent: {},
      selectedRooms: [],
    };

    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentWillMount(){
    this.loadEvents();
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  closeModal = (event) => {
    this.setState({
      reservationModalIsOpen: false,
      filterModalIsOpen: false
    });
  }

  async loadEvents() {
    const reservations = await fetchAndFormatAllReservations(this.context.project || 'sportparkzuid');

    this.setState({
      ...this.state,
      events: reservations.map(event => ({
        ...event,
        id: event.id,
        title: (event.club ? event.club + ': ' : '') + event.description,
        originalStart: event.start,
        originalEnd: event.end,
        start: moment(event.start._seconds * 1000).toDate(),
        end: event.end ? moment(event.end._seconds * 1000).toDate() : moment((event.start._seconds + 1) * 1000).toDate(),
        allDay: event.allDay
      }))
    });
  }

  refresh = () => {
    this.setState({ events: null });
    this.loadEvents();
  }

  renderBackdrop(props) {
    return <div {...props} style={backdropStyle} />;
  }

  getActiveRooms() {
    const { selectedRooms } = this.state;

    return selectedRooms.length > 0 ? selectedRooms : this.context.rooms;
  }

  toggleActiveRoom = room => () => {
    const { selectedRooms } = this.state;

    this.setState({
      selectedRooms: selectedRooms.indexOf(room) > -1 ? selectedRooms.filter(s => s !== room) : [...selectedRooms, room]
    })
  }

  openFilterModal = () => {
    this.setState({
      filterModalIsOpen: true
    })
  }

  closeFilterModal = () => {
    this.setState({
      filterModalIsOpen: false
    });
  }

  render() {
    let { events, selectedEvent, selectedRooms } = this.state;

    let { height = '100vh', classes } = this.props;

    const activeRooms = this.getActiveRooms();

    if (events == null) return 'laden';

    events = events.filter(event => event.rooms.length === 0 || (activeRooms.filter(activeRoom => event.rooms.indexOf(activeRoom) > -1)).length > 0)

    const clickEvent = this.props.clickEvent || ((event) => {
      this.setState({ ...this.state, selectedEvent: event, reservationModalIsOpen: true });
    });

    return (
      <div style={{ height }}>
        <Modal
          open={this.state.reservationModalIsOpen}
          onClose={this.closeModal}
          style={{ background: 'rgba(255, 255, 255, 0.7)' }}
          BackdropComponent={this.renderBackdrop}
        >
          <div style={getModalStyle()} className={classes.paper}>
            <Typography variant="subtitle1" id="simple-modal-description">
              <Reservation reservation={selectedEvent} onClose={this.closeModal}></Reservation>
            </Typography>
          </div>
        </Modal>

        <Modal
          open={this.state.filterModalIsOpen}
          onClose={this.closeModal}
          style={{ background: 'rgba(255, 255, 255, 0.7)' }}
          BackdropComponent={this.renderBackdrop}
        >
          <div style={getModalStyle()} className={classes.paper}>
            <Typography variant="subtitle1" id="simple-modal-description">
              <Card className={classes.card}>
                <CardContent>
                  <div><small><i>{selectedRooms.length === 0 ? 'Alle' : selectedRooms.length} ruimte{selectedRooms.length !== 1 ? 's' : '' } geselecteerd</i></small></div>
                  {this.context.rooms.map(room => <RoomButton key={room} selected={selectedRooms.indexOf(room) > -1} onClick={this.toggleActiveRoom(room)} name={room}></RoomButton>)}
                </CardContent>
                <CardActions style={{margin: '-20px 0 0 0'}}>
                  <Button style={{ float: 'right' }} size="small" onClick={this.closeFilterModal}> Sluiten</Button>
                </CardActions>
              </Card>
            </Typography>
          </div>
        </Modal>

        <Button onClick={this.openFilterModal}><FilterIcon></FilterIcon>&nbsp;&nbsp;Filter op ruimtes</Button>

        <BigCalendar
          eventPropGetter={(eventStyleGetter)}
          messages={messages}
          events={events}
          views={['day', 'week', 'month']}
          defaultView='week'
          step={60}
          showMultiDayTimes
          defaultDate={new Date()}
          localizer={localizer}
          onSelectEvent={clickEvent}
        />
      </div>
    );
  }
}

export default withStyles(styles)(Calendar);
