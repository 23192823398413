import React, {useEffect} from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import moment from 'moment';
import makeInvoice from '../../form/makeInvoice';
import Invoice from '../../form/Invoice';

const useStyles = makeStyles(theme => ({
    infoRow : {
        marginBottom: 12
    }
  }));

const Step4 = (props) => {
    const classes = useStyles();

    const {setVerifier} = props;

    useEffect(() => {
        setVerifier({
            verify: () => {
                return true;
            }
        });
    }, [])

    const {steps} = props;
    // const steps = { "step1": { "type": "partner", "personal": { "email": "jurrevriesen@gmail.com", "reservationCode": "123456789" } }, "step2": { "type": "training", "description": "Dit is een test training", "amountChangingRooms": "15", "timeSpan": { "timeStart": "2020-05-17T08:00:47.786Z", "timeEnd": "2020-05-17T10:00:47.787Z", "morning": false, "noon": false, "evening": false, "date": "2020-05-15T14:45:00.000Z" } }, "step3": { "rooms": ["ATB baan", "veld 3"] } };

    const {step1, step2, step3} = steps;

    const {type} = step1;

    return <>
    <Grid container>
        <Grid item item md={6}>
            <Typography variant="h6" gutterBottom component="h3">Gegevens Aanvrager</Typography>

            <Typography className={classes.infoRow}><b>Email:</b><br/> {step1.personal.email}</Typography>
            {type === 'partner' && <>
                <Typography className={classes.infoRow}><b>Reserveringscode:</b><br/> {step1.personal.reservationCode}</Typography>
            </>}

        </Grid>

        <Grid item item md={6}>
            <Typography variant="h6" gutterBottom component="h3">Gegevens Activiteit</Typography>

            <Typography className={classes.infoRow}><b>Type:</b><br/> {step2.type}</Typography>
            <Typography className={classes.infoRow}><b>Omschrijving:</b><br/> {step2.description}</Typography>
            <Typography className={classes.infoRow}><b>Tijdspan:</b><br/>Van {moment(step2.timeSpan.timeStart).format('DD-MM-YYYY hh:mm ')} tot {moment(step2.timeSpan.timeEnd).format('DD-MM-YYYY hh:mm ')}</Typography>
            <Typography className={classes.infoRow}><b>Ruimtes:</b><br/> {step3.rooms.join(', ')}</Typography>

            <Invoice invoice={makeInvoice(false, false, false, step3.rooms, moment(step2.timeSpan.timeStart).format("hh:mm"), moment(step2.timeSpan.timeEnd).format("hh:mm"))}></Invoice>

        </Grid>
    </Grid>
    </>
}

export default Step4;