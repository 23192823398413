import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import Icon from '@material-ui/icons/CalendarToday';

const Header = (props) => {
    return <AppBar position="relative">
        <Toolbar>
            <Icon></Icon>&nbsp;&nbsp;<Typography variant="h6" color="inherit" noWrap>Agendr</Typography>
        </Toolbar>
    </AppBar>
}

export default Header;