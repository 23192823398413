import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { ReactComponent as Plattegrond } from './plattegrond.svg'
import './Map.css'

const styles = {

};

class Map extends Component {

    selectableClass = 'selectable';

    constructor(props) {
        super(props);
        this.init();
        setTimeout(() => {
            this.UNSAFE_componentWillReceiveProps(props);
        }, 0);
    }

    UNSAFE_componentWillReceiveProps(props) {
        let { selected = [] } = props;

        const selectables = document.getElementsByClassName(this.selectableClass);
    
        for (var i = 0; i < selectables.length; i++) {
            const el = selectables[i];
            const id = '' + el.getAttribute('id');

            if(selected.indexOf(id) !== -1){
                el.setAttribute('class', `${this.selectableClass} selected`);
            }else{
                el.setAttribute('class', this.selectableClass);
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.eventHandler);
    }

    init = () => {
        this.liveClick('selectable', (event, el) => {
            const className = '' + el.getAttribute('class');
            const id = '' + el.getAttribute('id');

            const needsToBeSelected = className.indexOf('selected') === -1;

            let { selected = [] } = this.props;

            if (needsToBeSelected) {
                el.setAttribute('class', `${this.selectableClass} selected`);
                selected.push(id);
            } else {
                el.setAttribute('class', this.selectableClass);
                selected = selected.filter(s => s !== id);
            }

            this.onSelectedUpdate(selected);
        })
    }

    onSelectedUpdate = (selected) => {
        this.props.onSelectedUpdate && this.props.onSelectedUpdate(selected);
    }

    liveClick(eventClass, cb) {
        this.eventHandler = function (event) {
            var el = event.target
                , found;

            while (el && !(found = ('' + el.getAttribute('class')).indexOf(`${eventClass}`) !== -1)) {
                el = el.parentElement;
            }

            if (found) {
                cb(event, el);
            }
        }

        document.addEventListener('click', this.eventHandler);
    }


    render() {
        return (
            <div className="map">
                <Plattegrond />
            </div>
        );
    }
}

export default withStyles(styles)(Map);
