import { List, ListItemText } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { InlineDatePicker } from 'material-ui-pickers';
import moment from 'moment';
import React, { Component } from 'react';

const styles = {
    textField: {
        width: '70%',
        labelWidth: 0
    },
    button: {
        width: '30%'
    }
};

class MultipleDateSelect extends Component {

    state = {
        date: moment(),
    }


    handleDateChange = name => (date, value) => {
        this.setState({
            date
        });
    };

    update = (values) => {
        this.props.onDatesSet(values);
    }

    add = () => {
        const {values = []} = this.props;

        const {date} = this.state;

        const updated =  values.concat([date])
            .filter(v => v !== "")
            .filter((value, index, self) =>  self.indexOf(value) === index)

        this.update(updated);
    }

    remove = (item) => {
        const {values = []} = this.props;

        const filtered = values.filter(v => v !== item);

        this.update(filtered);
    }

    render() {
        const { classes, values = [] } = this.props;

        return (
            <div>
                <List>
                    {values.map(value => (
                        <ListItem>
                            <ListItemText
                                primary={value.format('LL')}
                            />
                            <ListItemSecondaryAction>
                                <IconButton aria-label="Delete" onClick={() => {this.remove(value)}}>
                                    <DeleteIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}

                </List>

                {values.length === 0 ? <div>Er zijn nog geen afgelastingen toegevoegd<br/><br/></div> : ''}

                <InlineDatePicker
                    label="Datum"
                    value={this.state.date}
                    onChange={this.handleDateChange('date')}
                    className={classes.textField}
                    variant="outlined"
                />

                <IconButton className={classes.button} onClick={this.add}> <AddIcon /></IconButton>

            </div>
        );
    }
}

export default withStyles(styles)(MultipleDateSelect);
