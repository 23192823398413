import axios from 'axios';
import moment from 'moment';
import Constants from '../Constants';

export const fetchAndFormatAllReservations = async (project) => formatAllReservations(await fetchReservations(project));

const fetchReservations = async (project) => {
  return (await axios.get(Constants.apiUrl + project + '/all-reservations')).data;
}

const formatAllReservations = async (allReservations) => {
  const reservations = allReservations.normal;
  const recurringReservations = allReservations.recurring;
  recurringReservations.forEach(recurringReservation => {
    const start = moment(recurringReservation.from._seconds * 1000);
    const end = moment(recurringReservation.to._seconds * 1000);
    const day = recurringReservation.day;
    const { except = [] } = recurringReservation;
    const current = start.clone().day(-7);
    while (current.day(7 + day).isBefore(end)) {
      let startTime = recurringReservation.timeStart.split(':');
      let endTime = recurringReservation.timeEnd.split(':');
      if (startTime.length === 0) {
        startTime = ["00", "00"];
      }
      if (endTime.length === 0) {
        endTime = ["23", "59"];
      }
      let found = false;
      for (let e of except) {
        if (moment.unix(e._seconds).format("YYYY-MM-DD") === current.format("YYYY-MM-DD")) {
          found = true;
          break;
        }
      }
      if (found) {
        continue;
      }
      const start = current.clone().hour(startTime[0]).minute(startTime[1]).unix();
      const end = current.clone().hour(endTime[0]).minute(endTime[1]).unix();
      reservations.push({
        ...recurringReservation,
        isRecurring: true,
        start: {
          _seconds: start
        },
        end: {
          _seconds: end
        }
      });
    }
    return recurringReservation;
  });
  return reservations;
}

export const filterReservationsInTimeSpan = (reservations, startTs, endTs) => reservations.filter(reservation => {

  if (reservation.allDay) {
    const start = moment(reservation.start._seconds * 1000).hours(0).minutes(0).unix();

    reservation = {
      ...reservation,
      start: { _seconds: start },
      end: { _seconds: start + (3600 * 24) }
    }
  }

  return reservation.start._seconds <= endTs && reservation.end._seconds >= startTs;

});

export const filterReservationsInTimeSpanWithRooms = (reservations, startTs, endTs, withRooms) => filterReservationsInTimeSpan(reservations, startTs, endTs).filter(reservation => {
  const { rooms = [] } = reservation;

  for (let room of withRooms) {
    if (rooms.indexOf(room) !== -1) {
      return true;
    }
  }

  return false;
})






