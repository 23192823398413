import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import Map from './map/Map';
import { Button, Typography } from '@material-ui/core';
import Checkboxes from './Checkboxes';
import SelectDateTime from './SelectDateTime';
import Personal from './Personal';
import Axios from 'axios';
import Constants from '../../Constants';
import Finished from './Finished';
import moment from 'moment';
import { filterReservationsInTimeSpanWithRooms, fetchAndFormatAllReservations } from '../../util/reservationUtil';
import ReservationsWarning from './ReservationsWarning';
import makeInvoice from './makeInvoice';
import Invoice from './Invoice';
import { roundToQuarter } from '../../util/momentUtil';

const styles = theme => ({
    checkboxesWrapper: {
        width: '30%',
        ['@media (max-width:780px)']: { // eslint-disable-line no-useless-computed-key
            width: '100%',
            height: 'calc(50vh - 100px)'
        },
        float: 'left',
        display: 'flex',
        flexDirection: 'column',
        ['@media (min-width:780px)']: { // eslint-disable-line no-useless-computed-key
            border: 'solid #f3f3f3 10px',
        },
        padding: '15px 0 15px 15px',
        boxSizing: 'border-box',
        background: '#f3f3f3',
        height: 'calc(100vh - 100px)'
    },
    checkboxes: {
        overflow: 'auto',
    },
    map: {
        width: '70%',
        ['@media (max-width:780px)']: { // eslint-disable-line no-useless-computed-key
            width: '100%',
            height: 'calc(50vh - 100px)'
        },
        float: 'left',
        height: 'calc(100vh - 100px)',
        ['@media (min-width:780px)']: { // eslint-disable-line no-useless-computed-key
            border: 'solid #f3f3f3 10px',
            borderLeft: 'none',
        },
        boxSizing: 'border-box',
        position: 'relative'
    },
    previousButton: {
        marginTop: 5,
        marginRight: 5
    },
    nextButton: {
        marginTop: 5,
        background: '#009933',
        color: 'white'
    },

    sendButton: {
        marginTop: 5,
    }
});

class Form extends Component {

    amountSteps = 3;

    state = {
        selectedRooms: [],
        event: {
            description: '',
            club: '',
            date: moment(),
            allDay: false,
            timeStart: roundToQuarter(moment()),
            timeEnd: roundToQuarter(moment()).add(1, 'hours'),
            typeOrganization: 'Sportvereniging',
            morning: false,
            noon: false,
            evening: false
        },
        personal: {
            name: '',
            email: '',
            phone: '',
            note: ''
        },
        showErrorsStep2: false,
        showErrorsStep3: false,
        step: 1,
        // step: 2 
    }

    constructor(props) {
        super(props)
        this.loadReservations();
    }

    onSelectedUpdate = (selected) => {
        this.setState({
            selectedRooms: selected
        });
    }

    onEventUpdate = (event) => {
        this.setState({
            event
        });
    }

    onPersonalUpdate = (personal) => {
        this.setState({
            personal
        });
    }

    nextStep = () => {
        let { step, showErrorsStep2, event } = this.state;


        if (step > 1 && Object.keys(SelectDateTime.getErrors(event)).length > 0) {
            step = 1;
            showErrorsStep2 = true;
        }

        this.setState({
            showErrorsStep2: showErrorsStep2,
            step: step + 1
        })
    }

    send = () => {
        let { personal } = this.state;

        let hasErrors = false;

        if (Object.keys(Personal.getErrors(personal)).length > 0) {
            hasErrors = true;
        }

        this.setState({
            showErrorsStep3: true,
        })

        if (!hasErrors) {
            this.save();
        }
    }

    fullEvent = () => {
        const { event, selectedRooms } = this.state;

        return {
            ...event,
            rooms: selectedRooms,
            date: event.date.format(Constants.momentDateFormat),
            timeStart: event.timeStart.format('HH:mm'),
            timeEnd: event.timeEnd.format('HH:mm')
        };
    }
    save = async () => {
        const { personal } = this.state;

        const newEvent = this.fullEvent();

        try {
            await Axios.post(Constants.apiUrl + 'sportparkzuid/requested-reservations', {
                event: newEvent,
                personal
            });

            this.setState({
                step: 4
            })
        } catch (err) {
            alert('Er is iets fout gegaan met het aanvragen.')
        }
    }

    previousStep = () => {
        const { step } = this.state;

        this.setState({
            showErrorsStep2: false,
            showErrorsStep3: false,
            step: step - 1
        })
    }

    reset = () => {
        this.setState({
            selectedRooms: [],
            event: {
                description: '',
                club: '',
                date: moment(),
                allDay: false,
                timeStart: moment(),
                timeEnd: moment(),
                typeOrganization: 'Sportvereniging'
            },
            showErrorsStep2: false,
            showErrorsStep3: false,
            step: 1
        })
    }

    async loadReservations() {
        this.setState({
            reservations: await fetchAndFormatAllReservations('sportparkzuid'),
        });
    }

    render() {
        const { classes } = this.props;
        const { step, selectedRooms, event, personal, showErrorsStep2, showErrorsStep3, reservations = [] } = this.state;

        const { date, timeStart, timeEnd, allDay } = event;

        const momentStart = date.clone().hours(allDay ? 0 : timeStart.hours()).minutes(allDay ? 0 : timeStart.minutes());
        const momentEnd = date.clone().hours(allDay ? 23 : timeEnd.hours()).minutes(allDay ? 59 : timeEnd.minutes());

        const conflictingReservations = filterReservationsInTimeSpanWithRooms(reservations, momentStart.unix(), momentEnd.unix(), selectedRooms);

        const invoice = makeInvoice(this.fullEvent());

        return (
            <div>
                {step === 1 ? <div>
                    <Typography variant="h4" gutterBottom component="h2"><b style={{ color: '#009933' }}>Stap 1.</b> Kies de ruimtes die je wilt reserveren</Typography>
                    <div className={classes.checkboxesWrapper}>
                        <div className={classes.selectedText}>{selectedRooms.length} geselecteerd</div>
                        <div id="checkboxesWrapper" className={classes.checkboxes}>
                            <Checkboxes onSelectedUpdate={this.onSelectedUpdate} selected={selectedRooms}></Checkboxes>
                        </div>
                    </div>
                    <div className={classes.map}>
                        <Map onSelectedUpdate={this.onSelectedUpdate} selected={selectedRooms}></Map>
                    </div>
                </div> : ''}

                {step === 2 ? <div>
                    <ReservationsWarning reservations={conflictingReservations}></ReservationsWarning>
                    <Typography variant="h4" gutterBottom component="h2"><b style={{ color: '#009933' }}>Stap 2.</b> Beschrijf de reservering</Typography>
                    <SelectDateTime showErrors={showErrorsStep2} onEventUpdate={this.onEventUpdate} event={event}></SelectDateTime>
                </div> : ''}

                {step === 3 ? <div>
                    <Typography variant="h4" gutterBottom component="h2"><b style={{ color: '#009933' }}>Stap 3.</b> Vul je contactgegevens in</Typography>
                    <Personal showErrors={showErrorsStep3} onPersonalUpdate={this.onPersonalUpdate} personal={personal}></Personal>
                </div> : ''}

                {step === 2 || step === 3 ? <div style={{ marginBottom: 15 }}>
                    <Invoice invoice={invoice}></Invoice>
                </div> : ''}

                {step === 4 ? <div>
                    <Finished onReset={this.reset}></Finished>
                </div> :
                    <div>
                        {step > 1 ? <Button className={classes.previousButton} color="default" variant="contained" onClick={this.previousStep}>Vorige</Button> : ''}

                        {step !== this.amountSteps ? <Button disabled={selectedRooms.length === 0 || conflictingReservations.length > 0} className={classes.nextButton} variant="contained" onClick={this.nextStep}>Volgende</Button> : ''}

                        {step === this.amountSteps ? <Button color="secondary" className={classes.sendButton} variant="contained" onClick={this.send}>Reservering Verzenden</Button> : ''}
                    </div>
                }
            </div>
        );
    }
}

export default withStyles(styles)(Form);
