import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Button, Modal, Card, CardContent, CardActions } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

function getModalStyle() {
    const top = 50;
    const left = 50;
  
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }


  const styles = theme => ({
    paper: {
      position: 'absolute',
      width: theme.spacing(50),
      backgroundColor: theme.palette.background.paper,
      outline: 'none',
    },
  });

  const backdropStyle = {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  };
  

class Invoice extends Component {

    state = {
        modalIsOpen: false
    }

    openModal = () => {
        this.setState({
            modalIsOpen: true
        })
    }

    closeModal = () => {
        this.setState({
            modalIsOpen: false
        })
    }

    formatPrice = (price) => {
        price = price < 0 ? 0 : price;
        return new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(price);
    }

    renderBackdrop(props) {
        return <div {...props} style={backdropStyle} />;
    }

    render() {
        const { invoice, classes } = this.props;

        const price = this.formatPrice(invoice.total)

        return (
            <div>
                <Modal
                open={this.state.modalIsOpen}
                onClose={this.closeModal}
                style={{background: 'rgba(255, 255, 255, 0.7)'}}
                BackdropComponent={this.renderBackdrop}
                >
                <div style={getModalStyle()} className={classes.paper}>
                <Card className={classes.card}>
                <CardContent>
                    <Typography variant="h5" component="h2" style={{marginBottom:15}}>
                        Kosten reservering
                    </Typography>

                    <table style={{width:'100%', textAlign:'left'}}>
                        <tr>
                            <th>Omschrijving</th>
                            <th>Bedrag</th>
                        </tr>
                    {invoice.rows.map(row => <tr>
                        <td>{row.amount} {row.description}</td><td>{this.formatPrice(row.price)}</td>
                    </tr>)}
                    <tr>
                        <th></th> <th>{this.formatPrice(invoice.total)}</th>
                    </tr>
                    </table>

                </CardContent>
                <CardActions>
                    <Button style={{ float: 'right' }} size="small" onClick={this.closeModal}>Sluiten</Button>
                </CardActions>
            </Card>
                </div>
                </Modal>
                <div onClick={this.openModal}>
                    <b>Kosten: </b> {price} <InfoIcon style={{ position: 'relative', top: 5, cursor: 'pointer' }}></InfoIcon>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(Invoice);
