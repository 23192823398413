import { Button, Typography } from '@material-ui/core';
import React from 'react';
import { NavLink } from 'react-router-dom';
import confirmationImage from '../../img/undraw_confirmation.svg';

export default function Review() {
  return (
    <div style={{textAlign:'center'}}>
      <Typography variant="h6">Klaar!</Typography>
      <img src={confirmationImage} alt="" style={{width:'75%', margin:'20px 0'}}></img>
      <NavLink to="/dashboard"><Button variant="contained" color="primary">Ga naar het dashboard</Button></NavLink>
    </div>
  );
}
