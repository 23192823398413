import React, { useState } from 'react';
import Step1 from './steps/Step1';
import Step2 from './steps/Step2';
import { Container, Paper, makeStyles, Button, Typography } from '@material-ui/core';
import Step3 from './steps/Step3';
import Step4 from './steps/Step4';
import './Planner.css';
import moment from 'moment';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Finished from '../form/Finished';
import Axios from 'axios';
import Constants from '../../Constants';

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#652482',
            main: '#652482',
            dark: '#652482',
            contrastText: '#fff',
        },
        secondary: {
            light: '#00A29A',
            main: '#00A29A',
            dark: '#00A29A',
            contrastText: 'white',
        },
    },
});

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(3),
        backgroundColor: theme.palette.background.paper,
    },
    nextButton: {
        float: 'right',
        marginTop: theme.spacing(2)
    },
    previousButton: {
        float: 'left',
        marginTop: theme.spacing(2)
    }
}));

const Planner = (props) => {
    const [step, setStep] = useState(1);
    let [nextEnabled, setNextEnabled] = useState(false);

    const [verifier, setVerifier] = useState({ verify: () => true });

    const enableNext = () => setNextEnabled(true);

    const classes = useStyles();

    const next = async () => {
        if (verifier.verify() === true) {
            if (step === 4) {
                await sendReservation();
            }

            setStep(step + 1);
        }
    }

    const back = () => {
        setStep(step - 1);
    }

    const [step1, setStep1] = useState({
        type: 'partner',
        personal: {
            email: '',
            reservationCode: ''
        }
    });

    if (step1.type === 'guest') {
        nextEnabled = true;
    }

    const [step2, setStep2] = useState({
        type: 'training',
        description: '',
        amountChangingRooms: 0,
        timeSpan: {
            timeStart: moment(),
            timeEnd: moment(),
            morning: false,
            noon: false,
            evening: false,
        }
    });

    const [step3, setStep3] = useState({
        rooms: []
    });

    const sendReservation = async () => {
        await Axios.post(Constants.apiUrl + 'sportparkzuid/requested-reservations', {
            step1, step2, step3
        });
    }

    const reset = () => {
        window.location.reload(false);
    }

    return <MuiThemeProvider theme={theme}>
        <Container>
            {step === 1 && <div style={{ textAlign: 'center' }}>
                <img style={{ marginTop: 56, marginBottom: 18 }} src="https://image.jimcdn.com/app/cms/image/transf/dimension=950x10000:format=png/path/sd58e1880a22342c4/image/i5791f714ea73a5fd/version/1541776420/image.png"></img>
                <Typography variant="h4" gutterBottom component="h1">Activiteit(en) Planner Stichting Sportpark Zuid</Typography>

                <div>
                    <Typography variant="h5" gutterBottom component="h2">Spelregels en Randvooraarden</Typography>
                    <ul style={{ listStylePosition: 'inside' }}>
                        <li>Getoonde prijzen zijn exclusief toeslagen en BTW</li>
                        <li>Activiteiten uiterlijk 72 uur van te voren inplannen. Groten evenementen uiterlijk twee weken van te voren.</li>
                    </ul>
                    <br></br>
                </div>
            </div>}
            <Paper className={classes.paper}>
                {step === 1 && <Step1 next={next} enableNext={enableNext} step={step1} setStep={setStep1} setVerifier={setVerifier}></Step1>}
                {step === 2 && <Step2 next={next} enableNext={enableNext} step={step2} setStep={setStep2} setVerifier={setVerifier}></Step2>}
                {step === 3 && <Step3 next={next} enableNext={enableNext} step={step3} step2={step2} setStep={setStep3} setVerifier={setVerifier}></Step3>}
                {step === 4 && <Step4 next={next} enableNext={enableNext} steps={{ step1, step2, step3 }} setVerifier={setVerifier}></Step4>}
                {step === 5 && <Finished onReset={reset}></Finished>}
            </Paper>
            {step > 1 && <Button color="secondary" className={classes.previousButton} variant="contained" onClick={() => { back() }}>Terug</Button>}

            {step < 5 && <Button style={{ marginBottom: 15 }} color="secondary" disabled={!nextEnabled} className={classes.nextButton} variant="contained" onClick={() => { next() }}>
                {step == 1 ? 'Starten' : step == 4 ? 'Reserveren' : 'Volgende'}
            </Button>}

        </Container>
    </MuiThemeProvider>
}

export default Planner;