import React, { useState, useEffect } from 'react';
import { Typography, Button, TextField, makeStyles, Grid } from '@material-ui/core';
import Axios from 'axios';
import Constants from "../../../Constants";


const RequestCodeForm = (props) => {
    const { enableNext, setPersonal, personal, setVerifier } = props;

    useEffect(() => {
        setVerifier({
            verify: () => {
                return true;
            }
        });
    }, [])

    const [errors, setErrors] = useState({});

    const classes = useStyles();

    const [requested, setRequested] = useState(false);

    const handleChange = (name) => (e) => {
        setPersonal({ ...personal, [name]: e.target.value });
    }

    const requestCode = async () => {
        const { email } = personal;

        try {
            await Axios.post(`${Constants.apiUrl}sportparkzuid/request-reservation-code`, {
                email
            });

            setErrors({ ...errors, emailNotFound: false });
            setRequested(true)
        } catch (err) {
            if (err.response.status === 403) {
                setErrors({ ...errors, emailNotFound: true });
            }
        }
    }

    const validateCode = async () => {
        try {
            await Axios.post(`${Constants.apiUrl}sportparkzuid/validate-reservation-code`, {
                reservationCode: personal.reservationCode
            });

            setErrors({ ...errors, reservationCodeInvalid: false });
            enableNext();
        } catch (err) {
            setErrors({ ...errors, reservationCodeInvalid: true });
        }
    }

    return <>
        {!requested ? <>
            <div>
                <TextField
                    label="Email (verplicht)"
                    error={errors.emailNotFound}
                    helperText={errors.emailNotFound ? 'Dit emailadres is niet geregistreerd als partner': ''}
                    value={personal.email}
                    onChange={handleChange('email')}
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                />
            </div>
            <Button variant="contained" color="secondary" onClick={requestCode}>Vraag reserveringscode aan</Button>
        </> :
            <>
                <div>
                    {errors.reservationCodeInvalid && <div style={{ color: 'red' }}>De code klopt niet</div>}
                    <TextField
                        label="Voer reserveringscode in"
                        className={classes.textField}
                        margin="normal"
                        variant="outlined"
                        value={personal.reservationCode}
                        onChange={handleChange('reservationCode')}
                    />
                </div>
                <Button variant="contained" color="secondary" onClick={validateCode}>Controleer code</Button>
            </>}

    </>
}

const useStyles = makeStyles(theme => ({
    textField : {
        maxWidth: 300,
        width: '100%'
    }
}));

const GuestForm = (props) => {
    const { setVerifier, personal, setPersonal } = props;

    const classes = useStyles();

    console.log(classes)

    const [errors, setErrors] = useState({});

    const handleChange = (name) => (e) => {
        setPersonal({ ...personal, [name]: e.target.value });
    }

    useEffect(() => {
        setVerifier({
            verify: () => {
                const notFilled = ['name', 'email', 'phone', 'address', 'postalCode', 'place'].filter(key => !personal[key])

                setErrors(notFilled.reduce((errors, value) => ({...errors, [value] : "Dit veld is verplicht" }), {}));

                return !notFilled.length;
            }
        });
    }, [setErrors, personal])

    return <Grid container>
        <Grid item item md={6}>
        <br/>
        <Typography variant="h6" gutterBottom component="h3">Contact</Typography>   
        <div>
            <TextField
                id="outlined-name"
                label="Bedrijf/Organisatie/Vereniging"
                error={errors.organisation !== undefined}
                helperText={errors.organisation || ''}
                value={personal.organisation}
                onChange={handleChange('organisation')}
                className={classes.textField}
                margin="normal"
                variant="outlined"
            />
        </div>
        <div>
            <TextField
                id="outlined-name"
                label="Naam (verplicht)"
                error={errors.name !== undefined}
                helperText={errors.name || ''}
                value={personal.name}
                onChange={handleChange('name')}
                className={classes.textField}
                margin="normal"
                variant="outlined"
            />
        </div>
        <div>
            <TextField
                id="outlined-name"
                label="Email (verplicht)"
                error={errors.email !== undefined}
                helperText={errors.email || ''}
                value={personal.email}
                onChange={handleChange('email')}
                className={classes.textField}
                margin="normal"
                variant="outlined"
            />
        </div>
        <div>
            <TextField
                id="outlined-name"
                label="Telefoonnummer (verplicht)"
                error={errors.phone !== undefined}
                helperText={errors.phone || ''}
                value={personal.phone}
                onChange={handleChange('phone')}
                className={classes.textField}
                margin="normal"
                variant="outlined"
            />
        </div>

        </Grid>
        <Grid item md={6}>
        <br/>
        <Typography variant="h6" gutterBottom component="h3">Adres</Typography>   
        <div>
            <TextField
                id="outlined-name"
                label="Straatnaam + huisnummer (verplicht)"
                error={errors.address !== undefined}
                helperText={errors.address || ''}
                value={personal.address}
                onChange={handleChange('address')}
                className={classes.textField}
                margin="normal"
                variant="outlined"
            />
        </div>
        <div>
            <TextField
                id="outlined-name"
                label="Postcode (verplicht)"
                error={errors.postalCode !== undefined}
                helperText={errors.postalCode || ''}
                value={personal.postalCode}
                onChange={handleChange('postalCode')}
                className={classes.textField}
                margin="normal"
                variant="outlined"
            />
        </div>
        <div>
            <TextField
                id="outlined-name"
                label="Plaats (verplicht)"
                error={errors.place !== undefined}
                helperText={errors.place || ''}
                value={personal.place}
                onChange={handleChange('place')}
                className={classes.textField}
                margin="normal"
                variant="outlined"
            />
        </div>
        </Grid>

    </Grid>
}

const Step1 = (props) => {
    const { next, enableNext, step, setStep, setVerifier } = props;

    const { type, personal } = step;

    const setType = (type) => setStep({ ...step, type });

    const setPersonal = (personal) => setStep({ ...step, personal });

    return <div>


        <div>
            <Typography variant="h6" gutterBottom component="h3">Activiteit inplannen als</Typography>
            <Button color="primary" variant={type === "partner" ? 'contained' : undefined} onClick={() => setType('partner')}>Partner</Button>
            <Button color="primary" variant={type === "beach" ? 'contained' : undefined} onClick={() => setType('beach')}>Beach</Button>
            <Button color="primary" variant={type === "guest" ? 'contained' : undefined} onClick={() => setType('guest')}>Gast</Button>
        </div>

        <div>
            {(type === 'partner' || type === 'beach') && <RequestCodeForm setVerifier={setVerifier} setPersonal={setPersonal} personal={personal} enableNext={enableNext}></RequestCodeForm>}
            {type === 'guest' && <GuestForm setVerifier={setVerifier} setPersonal={setPersonal} personal={personal} ></GuestForm>}
        </div>
    </div>
}

export default Step1;