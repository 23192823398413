import React, { Component } from 'react';
import Calendar from './components/agenda/Calendar';
import Form from './components/form/Form'
import { Router, Route, Switch } from 'react-router';
import { createBrowserHistory } from 'history';
import Admin from './components/admin/Admin';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import Landing from './components/landing/Landing';
import Checkout from './components/checkout/Checkout';
import { Auth0Provider } from "./react-auth0-spa";
import Planner from './components/planner/Planner';

const theme = createMuiTheme({});

const history = createBrowserHistory();

const config = {
  "domain": "agenda.eu.auth0.com",
  "clientId": "mZaAk8sEDHA4e6Qc6BnGqJ1COZkw9rfP",
  "api" : "api"
};

const onRedirectCallback = appState => {
  history.push("/register");
};


class App extends Component {
  render() {
    return (
      <Auth0Provider
        domain={config.domain}
        client_id={config.clientId}
        redirect_uri={`${window.location.origin}`}
        onRedirectCallback={onRedirectCallback}
        audience={config.api}
      >
        <div className="App">
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <MuiThemeProvider theme={theme}>
              <Router history={history}>
                <Switch>
                  <Route path="/agenda" component={Calendar} />
                  <Route path="/form" component={Form} />
                  <Route path="/planner" component={Planner} />
                  <Route exact path="/dashboard" component={Admin} />
                  <Route exact path="/dashboard/:project" component={Admin} />
                  <Route path="/register" component={Checkout} />
                  <Route exact path="" component={Landing} />
                </Switch>
              </Router>
            </MuiThemeProvider>
          </MuiPickersUtilsProvider>
        </div>
      </Auth0Provider>
    );
  }
}

export default App;
