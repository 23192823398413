import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const styles = {
    icon: {
        position: 'relative',
        top: 6,
        color: '#019833'
    },
    button: {
        background: '#019833',
        color: 'white'
    }
};

class Finished extends Component {


    render() {
        const { classes, onReset } = this.props;

        return (
            <div>
                <Typography component="h2" variant="h4" gutterBottom>
                    <CheckCircleIcon className={classes.icon} fontSize={'large'}></CheckCircleIcon> De reserveringsaanvraag is verstuurd!
                </Typography>
                <Typography>
                    Binnenkort wordt er contact met je opgenomen en laten we je weten of de aanvraag is goedgekeurd.
                </Typography>
                <br />
                <Button onClick={onReset} className={classes.button} variant="contained" color="default">Plaats nog een reservering</Button>
            </div>
        );
    }
}

export default withStyles(styles)(Finished);
