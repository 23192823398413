import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import Constants from '../../Constants';
import { useMediaQuery } from '@material-ui/core';

const styles = {

};

const notAbleToSelect = [        
    'kantine',
    'kleedkamer 1',
    'kleedkamer 2',
    'kleedkamer 3',
    'kleedkamer 4',
    'kleedkamer 5',
    'kleedkamer 6',
    'kleedkamer 7',
    'kleedkamer 8',
    'MF-ruimte'
]
class Checkboxes extends Component {


    handleChange = name => event => {
        const checked = event.target.checked;

        let { selected } = this.props;

        if (checked) {
            selected.push(name);
        }else{
            selected = selected.filter(s => s !== name);
        }

        this.onSelectedUpdate(selected);
    };

    onSelectedUpdate = (selected) => {
        this.props.onSelectedUpdate && this.props.onSelectedUpdate(selected);
    }

    render() {
        const { classes, reserved = []} = this.props;

        const rooms = Constants.rooms.filter(room => !notAbleToSelect.includes(room));

        let { selected = [] } = this.props;

        return (
            <div >
                <FormControl component="fieldset" className={classes.formControl}>
                    <FormGroup style={{maxHeight: this.props.mediaQuery ? 250 : 'auto'}}>
                        {rooms.map(room => <FormControlLabel key={room}
                            control={
                                <Checkbox disabled={reserved.includes(room)} checked={selected.indexOf(room) !== -1} onChange={this.handleChange(room)} value={room} />
                            }
                            label={room}
                        />)}

                    </FormGroup>
                </FormControl> 
            </div>
        );
    }
}

const withMediaQuery = (...args) => Component => props => {
    const mediaQuery = useMediaQuery(...args);
    return <Component mediaQuery={mediaQuery} {...props} />;
  };

export default withMediaQuery('(min-width:1100px)')(withStyles(styles)(Checkboxes));
