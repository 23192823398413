import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useHistory } from "react-router-dom";
import { useAuth0 } from "../../react-auth0-spa";
import SignIn from '../admin/signIn/SignIn';

export default function AddressForm(props) {
  let history = useHistory();

  const { loading, isAuthenticated, appUser } = useAuth0();

  if(loading){
    return '';
  }

  if(isAuthenticated){
    props.handleNext();
  }

  if(appUser && appUser.projects.length > 0){
    history.push('/dashboard');
  }

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Verbind met een account
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
            <div>
                {!isAuthenticated ? <SignIn withHeader={false}></SignIn> : 'Logged in'} 
            </div>

        </Grid>
      </Grid>
    </React.Fragment>
  );
}
