import Constants from "../../Constants";

export default function makeInvoice(evening, morning, noon, rooms, timeStart, timeEnd){
    const invoice = {rows:[]};

    if(evening === true || morning === true || noon === true){
        let count = 0;

        evening === true && count++; morning === true && count++; noon === true && count++;

        invoice.rows = invoice.rows.concat(rooms.map(room => ({
            amount: count,
            description: `dagdeel ${room}`,
            price: Constants.pricings[room].dayPart * count
        })));
    }else{
        let hours = (parseFloat(timeEnd) + (parseFloat(timeEnd.split(":")[1] / 60))) - (parseFloat(timeStart) + (parseFloat(timeStart.split(":")[1] / 60)));
        hours = Math.ceil(hours);

        invoice.rows = invoice.rows.concat(rooms.map(room => ({
            amount: hours,
            description: `uur ${room}`,
            price: Constants.pricings[room].hour * hours
        })));
    }

    invoice.total = invoice.rows.reduce((val, row) => row.price + val, 0);

    return invoice;
}