import { Button, Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import moment from 'moment';
import React from 'react';
import { timeToMoment } from '../../../../util/momentUtil';
import Calendar from '../../../agenda/Calendar';
import AddEventDialog from './dialogs/AddEventDialog';
import AddRecurringEventDialog from './dialogs/AddRecurringEventDialog';
import { createTheme } from '../../createTheme';
import DashboardContext from '../DashboardContext';
class CalendarAdmin extends React.Component {

    static contextType = DashboardContext;

    state = {
        eventDialogOpen: false,
        recurringEventDialogOpen: false,
        selectedEvent: null,
        recurringDialog: false,
    };

    constructor() {
        super();
        this.calendarRef = React.createRef();
    }

    handleNewEventDialogOpen = () => {
        this.setState({ selectedEvent: null, eventDialogOpen: true, recurringDialog: false });
    };

    handleNewRecurringEventDialogOpen = () => {
        this.setState({ selectedEvent: null, recurringEventDialogOpen: true, recurringDialog: true });
    };

    handleDialogClose = () => {
        this.setState({ recurringEventDialogOpen: false, eventDialogOpen: false });
        this.calendarRef.refresh();
    };

    setPage = name => () => {
        this.setState({
            page: name
        });
    }

    editEvent = (event) => {
        if (event.isRecurring) {
            event = { ...event };

            event.timeStart = timeToMoment(event.timeStart);
            event.timeEnd = timeToMoment(event.timeEnd);
            event.from = moment.unix(event.from._seconds);
            event.to = moment.unix(event.to._seconds);

            event.except = (event.except || []).map(d => moment.unix(d._seconds));

            this.setState({
                selectedEvent: event,
                recurringEventDialogOpen: true,
            });
        } else {
            this.setState({
                selectedEvent: {
                    recurringDialog: false,
                    id: event.id,
                    allDay: event.allDay,
                    description: event.description,
                    club: event.club,
                    selectedRooms: event.rooms,
                    rooms: event.rooms,
                    date: moment.unix((event.originalStart || {})._seconds),
                    timeStart: moment.unix((event.originalStart || {})._seconds),
                    timeEnd: moment.unix((event.originalEnd || {})._seconds)
                },
                eventDialogOpen: true
            });
        }

    };

    render() {
        const { classes } = this.props;
        const { selectedEvent, eventDialogOpen, recurringEventDialogOpen } = this.state;

        return (
            <div>
                <Typography variant="h4" gutterBottom component="h2">
                    Agenda
                </Typography>
                <Typography>
                    <Button variant="contained" color="primary" onClick={this.handleNewEventDialogOpen}><AddIcon></AddIcon> eenmalig evenement</Button>&nbsp;
                    <Button variant="contained" color="secondary" onClick={this.handleNewRecurringEventDialogOpen}><AddIcon></AddIcon> herhalend evenement</Button>

                    {recurringEventDialogOpen ?
                        <AddRecurringEventDialog selectedevent={selectedEvent} open={true} onClose={this.handleDialogClose}></AddRecurringEventDialog>
                        : ''}

                    {eventDialogOpen ?
                        <AddEventDialog selectedevent={selectedEvent} open={true} onClose={this.handleDialogClose}></AddEventDialog>
                        : ''}
                </Typography>
                <br />
                <Typography component="div" className={classes.tableContainer}>
                    <Paper className={classes.inner}>
                        <Calendar clickEvent={this.editEvent} innerRef={ref => (this.calendarRef = ref)} height="calc(100vh - 250px)" />
                    </Paper>
                </Typography>
            </div>
        );
    }
}

export default withStyles(createTheme())(CalendarAdmin);