import { Button } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import React, { Component } from 'react';
import Constants from '../../Constants';

const styles = {
    card: {
        minWidth: 275,
    },
    header: {
        margin: '-16px -16px 15px',
        padding: '15px',
        color: 'white'
    },
    pos: {
        marginBottom: -12,
    }
};

class Reservation extends Component {
    render() {
        const { classes } = this.props;
        const { reservation, onClose } = this.props;

        const { rooms = [] } = reservation;

        const background = Constants.clubColors[reservation.club] || '#093';

        const start = moment(reservation.originalStart._seconds * 1000);
        const end = moment((reservation.originalEnd || reservation.originalStart)._seconds * 1000);
        return (
            <Card className={classes.card}>
                <CardContent>
                    <Typography variant="h5" component="h2" className={classes.header} style={{ background }}>
                        {(reservation.club ? reservation.club + ': ' : '') + reservation.description}
                    </Typography>
                    <Typography className={classes.pos} color="textSecondary">
                        {start.format('LL')} van {start.format('HH:mm')} tot {end.format('HH:mm')}<br />
                        Gereserveerde&nbsp;

                        {rooms.length === 1 ? (<span>ruimte: {rooms[0]}</span>) : ''}

                        {rooms.length > 1 ?
                            (<span>ruimte{rooms.length > 1 ? 's' : ''}:
                                <ul>
                                    {rooms.map(x => <li key={x}>{x}</li>)}
                                </ul>
                            </span>)
                            : ''}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button style={{ float: 'right' }} size="small" onClick={onClose}>Sluiten</Button>
                </CardActions>
            </Card>
        );

    }
}

export default withStyles(styles)(Reservation);
