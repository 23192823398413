import { Checkbox, Chip, FormControl, MenuItem, OutlinedInput, Select, TextField, FormControlLabel, Switch } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from "@material-ui/core/InputLabel";
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import ReactDOM from "react-dom";
import Axios from 'axios';
import Constants from '../../../../../Constants';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';
import DashboardContext from '../../DashboardContext'
import { InlineDatePicker, InlineTimePicker } from 'material-ui-pickers';

const styles = theme => ({
    textField: {
        width: '100%',
        labelWidth: 0
    },
});

class AddEventDialog extends React.Component {

    static contextType = DashboardContext;

    emptyEvent = {
        description: '',
        club: '',
        date: moment(),
        timeStart: moment(),
        timeEnd: moment(),
        selectedRooms: [],
        allDay: false,
        createNewOne: false
    };

    constructor(props) {
        super(props);
        this.state = {
            event: { ...this.emptyEvent },
            labelWidth: 100
        }
        this.UNSAFE_componentWillReceiveProps(props);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.state.event = nextProps.selectedevent || this.emptyEvent; //eslint-disable-line
    }

    inEditModus = () => {
        return this.state.event.id !== null && this.state.event.id !== undefined;
    }

    save = async () => {
        const { project } = this.context
        const event = this.state.event;

        let momentStart = event.date.clone().hours(event.timeStart.hours()).minutes(event.timeStart.minutes());
        let momentEnd = event.date.clone().hours(event.timeEnd.hours()).minutes(event.timeEnd.minutes());

        const obj = {
            start: momentStart.toDate(),
            end: momentEnd.toDate(),
            description: event.description,
            club: event.club,
            rooms: event.selectedRooms,
            allDay: event.allDay
        };

        try {
            if (this.inEditModus()) {
                await Axios.put(Constants.apiUrl + project + '/reservations/' + event.id, obj);
            } else {
                await Axios.post(Constants.apiUrl + project + '/reservations', obj);
            }

            if(!this.state.createNewOne){
                return this.handleClose();
            }

            alert('Het evenement is geplaatst!')
        } catch (err) {
            alert('Het evenement kon niet opgeslagen worden.')
        }

    };

    delete = async () => {
        const { project } = this.context

        try {
            await Axios.delete(Constants.apiUrl + project + '/reservations/' + this.state.event.id);
            this.handleClose();
        } catch (err) {
            alert('Het evenement kon niet verwijderd worden.')
        }
    }

    handleClose = () => {
        this.props.onClose();
    };

    handleListItemClick = value => {
        this.props.onClose(value);
    };

    handleChecked = name => event => {
        this.setState({
            event: {
                ...this.state.event,
                [name]: event.target.checked,
            }
        });
    };

    handleCreateNewOneChecked = event => {
        this.setState({
            createNewOne: event.target.checked
        })
    }

    handleDateChange = name => (date, value) => {
        this.setState({
            event: {
                ...this.state.event,
                [name]: date
            }
        });
    }

    handleChange = name => event => {
        this.setState({
            event: {
                ...this.state.event,
                [name]: event.target.value,
            }
        });
    };

    componentDidMount() {
        if (this.InputLabelRef) {
            this.setState({
                labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth
            });
        }
    }

    render() {
        const { classes, onClose, ...other } = this.props;

        const { rooms } = this.context;

        return (
            <Dialog onClose={this.handleClose} aria-labelledby="simple-dialog-title" {...other}>
                <DialogTitle id="simple-dialog-title">

                    Evenement {this.inEditModus() ? 'wijzigen' : 'toevoegen'}

                    {
                        this.inEditModus ? (<Button style={{ float: 'right' }} onClick={this.delete} color="secondary"><DeleteIcon></DeleteIcon></Button>) : ''
                    }

                </DialogTitle>
                <div style={{ padding: 15, marginTop: -30, maxWidth: 500, width: '100vw' }}>
                    <div>
                        <TextField
                            id="outlined-name"
                            label="Omschrijving"
                            value={this.state.event.description}
                            onChange={this.handleChange('description')}
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                        />
                    </div>
                    <div>
                        <TextField
                            id="outlined-name"
                            label="Vereniging"
                            value={this.state.event.club}
                            onChange={this.handleChange('club')}
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                        />
                    </div>
                    <br />
                    <div>

                        <InlineDatePicker
                            label="Datum (verplicht)"
                            className={classes.textField}
                            variant="outlined"
                            value={this.state.event.date}
                            onChange={this.handleDateChange('date')} >
                        </InlineDatePicker>
                        <br />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={this.state.event.allDay}
                                    onChange={this.handleChecked('allDay')}
                                    value="checkedA"
                                />
                            }
                            label="Hele dag"
                        />
                        <br /><br />

                        {!this.state.event.allDay ? <div>

                            <InlineTimePicker
                                ampm={false}
                                keyboard
                                label="Van"
                                className={classes.textField}
                                variant="outlined"
                                value={this.state.event.timeStart}
                                onChange={this.handleDateChange('timeStart')} >
                            </InlineTimePicker>

                            <br /><br />

                            <InlineTimePicker
                                ampm={false}
                                keyboard
                                label="Tot"
                                className={classes.textField}
                                variant="outlined"
                                value={this.state.event.timeEnd}
                                onChange={this.handleDateChange('timeEnd')} >
                            </InlineTimePicker>

                            &nbsp;
                         <br /><br />
                        </div> : ''}

                    </div>


                    <div>
                        <FormControl variant="outlined" className={classes.textField}>
                            <InputLabel
                                ref={ref => {
                                    this.InputLabelRef = ref;
                                }}
                                className={classes.textField}
                                htmlFor="select-multiple-chip"
                            >
                                Ruimtes
                            </InputLabel>
                            <Select
                                multiple
                                variant="outlined"
                                value={this.state.event.selectedRooms}
                                onChange={this.handleChange('selectedRooms')}
                                className={classes.textField}
                                input={<OutlinedInput labelWidth={this.state.labelWidth}
                                    id="select-multiple-chip" />}
                                renderValue={selected => (
                                    <div className={classes.chips}>
                                        {selected.map(value => (
                                            <Chip key={value} label={value} className={classes.chip} />
                                        ))}
                                    </div>
                                )}
                            >
                                {rooms.map(name => (
                                    <MenuItem key={name} value={name}>
                                        <Checkbox checked={this.state.event.selectedRooms.indexOf(name) > -1} />
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>

                    <br />

                    <Button variant="contained" color="primary" onClick={this.save}>Opslaan</Button>
                    &nbsp;&nbsp;
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.state.createNewOne}
                                onChange={this.handleCreateNewOneChecked}
                                value="checkedB"
                                color="primary"
                                inputProps={{
                                    'aria-label': 'onthouden',
                                }}
                            />}
                        label="Onthouden"
                    />

                    <Button style={{ float: "right" }} onClick={this.handleClose}>Annuleren</Button>
                </div>
            </Dialog>
        );
    }
}

export default withStyles(styles)(AddEventDialog);
