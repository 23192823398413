export default {
    apiUrl: window.location.hostname.indexOf('localhost') !== -1 ? 'http://localhost:3001/' : 'https://api.sportparkzuid-aalten.nl/',
    rooms: [
        'veld 1',
        'veld 2',
        'veld 3',
        '400 meterbaan',
        'wielerbaan',
        'ATB baan',
        'mini arena veld 1',
        'mini arena veld 2',
        'Kanjer arena',
        'beachveld 1',
        'beachveld 2',
        'beachveld 3',
        'boccebanen',
        'bestuurskamer',
        'kantine',
        'terras',
        'kleedkamer 1',
        'kleedkamer 2',
        'kleedkamer 3',
        'kleedkamer 4',
        'kleedkamer 5',
        'kleedkamer 6',
        'kleedkamer 7',
        'kleedkamer 8',
        'MF-ruimte',
        'sportzaal',
        'evenementen terrein',
        'combigebouw entree',
        'combigebouw grote zaal beneden',
        'combigebouw keuken',
        'combigebouw boven'
    ],
    pricings: {
        "veld 1": {
            hour: 15,
            dayPart: 30
        },
        "veld 2": {
            hour: 15,
            dayPart: 30
        },
        "veld 3": {
            hour: 15,
            dayPart: 30
        },
        "400 meterbaan": { "hour": 15, "daypart": 15 }, "wielerbaan": { "hour": 15, "daypart": 15 }, "ATB baan": { "hour": 15, "daypart": 15 }, "mini arena veld 1": { "hour": 15, "daypart": 15 }, "mini arena veld 2": { "hour": 15, "daypart": 15 }, "Kanjer arena": { "hour": 15, "daypart": 15 }, "beachveld 1": { "hour": 15, "daypart": 15 }, "beachveld 2": { "hour": 15, "daypart": 15 }, "beachveld 3": { "hour": 15, "daypart": 15 }, "boccebanen": { "hour": 15, "daypart": 15 }, "bestuurskamer": { "hour": 15, "daypart": 15 }, "kantine": { "hour": 15, "daypart": 15 }, "terras": { "hour": 15, "daypart": 15 }, "kleedkamer 1": { "hour": 15, "daypart": 15 }, "kleedkamer 2": { "hour": 15, "daypart": 15 }, "kleedkamer 3": { "hour": 15, "daypart": 15 }, "kleedkamer 4": { "hour": 15, "daypart": 15 }, "kleedkamer 5": { "hour": 15, "daypart": 15 }, "kleedkamer 6": { "hour": 15, "daypart": 15 }, "kleedkamer 7": { "hour": 15, "daypart": 15 }, "kleedkamer 8": { "hour": 15, "daypart": 15 }, "MF-ruimte": { "hour": 15, "daypart": 15 }, "sportzaal": { "hour": 15, "daypart": 15 }, "evenementen terrein": { "hour": 15, "daypart": 15 }, "combigebouw entree": { "hour": 15, "daypart": 15 }, "combigebouw grote zaal beneden": { "hour": 15, "daypart": 15 }, "combigebouw keuken": { "hour": 15, "daypart": 15 }, "combigebouw boven": { "hour": 15, "daypart": 15 }
    },
    clubColorsCalendar: {
        "AD'69": 'rgba(2, 150, 65, 0.8)',
        "Peddelaars": 'rgba(35, 44, 81, 0.8)',
        "Aladna": 'rgba(238, 103, 38, 0.8)',
        "Brichbouw": 'rgba(46, 158, 219, 0.8)',
        "Cosmo/Bambi BSO": 'rgb(250, 190, 54, 0.8)'
    },
    clubColors: {
        "AD'69": 'rgb(2, 150, 65)',
        "Peddelaars": 'rgb(35, 44, 81)',
        "Aladna": 'rgb(238, 103, 38)',
        "Brichbouw": 'rgb(46, 158, 219)',
        "Cosmo/Bambi BSO": 'rgb(250, 190, 54)'
    },
    clubs: [
        "AD'69", "Aladna", "BOVO", "Brichbouw", 'IJsvereniging "de Hare"',  "Peddelaars", "Silent World",  "Cosmo/Bambi BSO"
    ],
    dateFormat: 'yyyy-MM-dd',
    momentDateFormat: 'Y-MM-DD'
}
