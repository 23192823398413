import { Button, Dialog, DialogTitle, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Axios from 'axios';
import React, { Component } from 'react';
import makeInvoice from '../../../../form/makeInvoice';
import Constants from '../../../../../Constants';
import DashboardContext from '../../DashboardContext';

const styles = {
    textField: {
        width: '70%',
        labelWidth: 0
    },
    button: {
        width: '30%'
    }
};

class SendPaymentDialog extends Component {

    static contextType = DashboardContext;

    state = {
        subject: "Goedkeuring reserveeraanvraag Stichting Sportpark Zuid Aalten ",
        paymentLink: "",
        paymentId: null,
        mailText: "",
        closingText: "",
        email: ""
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };

    calculatePrice(event) {
        return 5;
    }

    nl2br (str, is_xhtml) {
        if (typeof str === 'undefined' || str === null) {
            return '';
        }
        var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    }

    send = async () =>  {
        const {project} = this.context;

        const { mailText, email, paymentLink, paymentId, closingText, subject, request } = this.state;

        try{

            request.paymentStatus = "In afwachting";
            request.paymentIds.push(paymentId);

            await Axios.put(`${Constants.apiUrl}${project}/requested-reservations/${request.id}`, request);
    

            await Axios.post(`${Constants.apiUrl}send-ideal-mail`, {
                to: email,
                subject,
                text: this.nl2br(mailText),
                link:paymentLink,
                closing:  this.nl2br(closingText)
            });
    
            this.props.onClose();

            alert("De email is verzonden");
        }catch(err){
            alert("De email kan niet verzonden worden.");
        }
    }

    formatPrice = (price) => {
        return new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(price);
    }

    async UNSAFE_componentWillReceiveProps(nextProps) {
        const {project} = this.context;

        if (nextProps.requestforpayment && nextProps.requestforpayment.event) {
            const {evening, morning, noon, rooms, date, timeEnd, timeStart} = nextProps.requestforpayment.event;
            const invoice = makeInvoice(evening, morning, noon, rooms, date, timeEnd, timeStart);

            let payment = await Axios.post(`${Constants.apiUrl}${project}/mollie-payment`, {
                amount: invoice.total,
            });

            const {requestforpayment} = nextProps;

            const invoiceText = invoice.rows.reduce((str, row) => {
                return `${str}- ${row.amount} ${row.description} ${this.formatPrice(row.price)}
`                
            }, '')

            this.setState({
                request: requestforpayment,
                email: requestforpayment.personal.email,
                paymentLink:payment.data.url,
                paymentId: payment.data.id,
                mailText: `Hallo,
                
De reserveeraanvraag voor de ruimtes ${requestforpayment.event.rooms.join(', ')} op ${requestforpayment.event.date} van ${requestforpayment.event.timeStart} tot ${requestforpayment.event.timeEnd} is goedgekeurd.

Via onderstaande knop kan de betaling van ${this.formatPrice(invoice.total)} via iDEAL voldaan worden. De kosten zijn als volgt berekend:

${invoiceText}`,
                closingText: `Met vriendelijke groet,`

            })
        }
    }

    render() {
        const { classes, onClose = { personal: {} }, ...other } = this.props;

        const { mailText, email, paymentLink, closingText, subject } = this.state;

        return (
            <Dialog onClose={this.handleClose} aria-labelledby="simple-dialog-title" {...other}>
                <DialogTitle id="simple-dialog-title">
                    Betaalverzoek versturen
                </DialogTitle>
                <br/>
                <div style={{ padding: 15, maxWidth: 500, marginTop: -30, width: '100vw' }}>
                    <div>
                        <TextField label="Onderwerp" style={{width:"100%"}} variant="outlined" value={subject} onChange={this.handleChange("subject")}></TextField>
                    </div>

                    <br/>

                    <div>
                        <TextField label="Naar e-mailadres" style={{width:"100%"}} variant="outlined" value={email} onChange={this.handleChange("email")}></TextField>
                    </div>

                    <br/>

                    <div>
                        <TextField label="Bericht" style={{width:"100%"}} multiline={true} variant="outlined" value={mailText} onChange={this.handleChange("mailText")}></TextField>
                    </div>

                    <br/>

                    <div>
                        <TextField label="Betaallink" style={{width:"100%"}} variant="outlined" value={paymentLink} onChange={this.handleChange("paymentLink")}></TextField>
                    </div>

                    <br />

                    <div>
                        <TextField label="Afsluiting" style={{width:"100%"}} multiline={true} variant="outlined" value={closingText} onChange={this.handleChange("closingText")}></TextField>
                    </div>

                    <br/>
                    <Button variant="contained" color="primary" onClick={this.send}>Verzend</Button>
                    <Button style={{float:'right'}} onClick={onClose}>Overslaan</Button>
                </div>

            </Dialog>
        );
    }
}

export default withStyles(styles)(SendPaymentDialog);
