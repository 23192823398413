import { Button, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { AssignmentReturned, CalendarToday, Room } from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu';
import classNames from 'classnames';
import React from 'react';
import { createTheme } from '../createTheme';
import CalendarAdmin from './calendarAdmin/CalenderAdmin';
import Requests from './requestsAdmin/Requests';
import Rooms from './roomsAdmin/Rooms';

class Dashboard extends React.Component {
  state = {
    appDrawerOpen: true,
    page: 'calendar'
  };

  handleToggleLeftDrawer = () => {
    this.setState({ appDrawerOpen: !this.state.appDrawerOpen });
  };

  setPage = name => () => {
    this.setState({
      page: name
    });
  }

  render() {
    const { classes } = this.props;
    const { page, appDrawerOpen } = this.state;

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="absolute"
          className={classNames(classes.appBar, this.state.open && classes.appBarShift)}
        >
          <Toolbar disableGutters={!this.state.open} className={classes.toolbar}>
            <IconButton
              color="inherit"
              aria-label="Toggle drawer"
              onClick={this.handleToggleLeftDrawer}
              className={classes.menuButton}>
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              Dashboard
            </Typography>
            <Button color="inherit" onClick={this.props.signOut}>
              Uitloggen
            </Button>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: classNames(classes.drawerPaper, !appDrawerOpen && classes.drawerPaperClose),
          }}
          style={{ marginTop: 64 }}
          open={appDrawerOpen}
        >
          <Divider />
          <List>
            <ListItem button onClick={this.setPage('calendar')}>
              <ListItemIcon>
                <CalendarToday />
              </ListItemIcon>
              <ListItemText primary="Agenda" />
            </ListItem>
            <Divider></Divider>
            <ListItem button onClick={this.setPage('requested-reservations')}>
              <ListItemIcon>
                <AssignmentReturned></AssignmentReturned>
              </ListItemIcon>
              <ListItemText primary="Aanvragen" />
            </ListItem>
            <Divider></Divider>
            <ListItem button onClick={this.setPage('rooms')}>
              <ListItemIcon>
                <Room></Room>
              </ListItemIcon>
              <ListItemText primary="Ruimtes" />
            </ListItem>
          </List>
          <Divider />
        </Drawer>
        <main className={classes.content}>
          
        {page === 'calendar' && <CalendarAdmin></CalendarAdmin> }
        {page === 'requested-reservations' && <Requests></Requests> }
        {page === 'rooms' && <Rooms></Rooms> }
        
          <div className={classes.appBarSpacer} />
        </main>
      </div>
    );
  }
}

export default withStyles(createTheme())(Dashboard);