import { withStyles } from '@material-ui/core/styles';
import { Warning } from '@material-ui/icons';
import moment from 'moment';
import React from 'react';

const styles = {
    textField : {
        maxWidth: 400,
        width: '100%'
    },
    warningWrapper : {
        background: '#fff1cc',
        border: 'solid #ff9900 2px',
        borderRadius: 5,
        padding: 15,
        color: '#945a02'
    },
    warningText: {
        position: "relative",
        top: -6,
        fontWeight: 700
    }
};


class ReservationsWarning extends React.Component {


    render() {
        const {classes, reservations = []} = this.props;

        if(reservations.length === 0){
            return '';
        }

        return (
            <div className={classes.warningWrapper}>
                <div>
                    <Warning></Warning>
                    <span className={classes.warningText}> De volgende ruimtes zijn al gereserveerd op de gekozen datum en tijd</span>
                </div>
                <ul>
                    {reservations.map(reservation => (<li key={reservation.id}>
                        {reservation.rooms.join(', ')} van {moment(reservation.start._seconds * 1000).format("HH:mm") } tot {moment(reservation.end._seconds * 1000).format("HH:mm") } voor {reservation.club ? reservation.club + ' ' : ''}{reservation.description}
                    </li>))}
                </ul>
            </div>
        );
    }
}

export default withStyles(styles)(ReservationsWarning);
