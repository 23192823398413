import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Axios from 'axios';
import { useAuth0 } from "../../react-auth0-spa";
import React, { useState } from 'react';
import Constants from '../../Constants';

export default function PaymentForm(props) {
  const [name, setName] = useState('');
  const [error, setError] = useState(undefined);

  const {refreshAppUser} = useAuth0();

  const submit = async () => {
    try{
      await Axios.post(`${Constants.apiUrl}project`, {
        name
      });
      await refreshAppUser();
      props.handleNext();
    }catch(err){
      setError("Helaas de gekozen naam is niet uniek");
    }
  }

  const back = async () => {
    // TODO add logout
    props.handleBack();
  }

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Naam van het project
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField error={!!error} id="cardName" label={error || 'Kies een unieke naam'} value={name} onChange={(e) => {
            setName(e.target.value);
            setError(undefined);
          }} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <Button onClick={back}>Terug</Button>
          <Button onClick={submit} variant="contained" color="primary">Verder</Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
