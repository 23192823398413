import { List, ListItemText, TextField, Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useEffect, useState, useContext } from 'react';
import DashboardContext from '../DashboardContext';
import Axios from "axios";
import Constants from "../../../../Constants";

const Rooms = () => {
    const { project } = useContext(DashboardContext)

    const [rooms, setRooms] = useState([]);
    const [name, setName] = useState('');

    const fetchRooms = async () => {
        const result = await Axios.get(`${Constants.apiUrl}${project}/rooms`)
        setRooms(result.data);
    }

    useEffect(() => {
        fetchRooms();
    }, [])

    const removeRoom = async (id) => {
        await Axios.delete(`${Constants.apiUrl}${project}/rooms/${id}`)
        await fetchRooms();
    }

    const addRoom = async () => {
        const room = {
            name
        }

        await Axios.post(`${Constants.apiUrl}${project}/rooms`, room);
        await fetchRooms();

        setName('');
    }

    return <>
        <List>
            {rooms.map(room => (
                <ListItem key={room.id}>
                    <ListItemText
                        primary={room.name}
                    />
                    <ListItemSecondaryAction>
                        <IconButton aria-label="Delete" onClick={() => { removeRoom(room.id) }}>
                            <DeleteIcon />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
            ))}
        </List>
        <TextField value={name} onChange={(e) => setName(e.target.value)}></TextField>
        <Button onClick={addRoom}>Add</Button>
    </>

}

export default Rooms;