import { Link, makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { fontWeight } from '@material-ui/system';


const useStyles = makeStyles(theme => ({
    footer: {
        backgroundColor: '#fafafa',
        padding: theme.spacing(6),
    },
    link: {
        fontWeight: 500
    }
}));

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                Your Website
        </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const Footer = (props) => {
    const classes = useStyles();
    return <footer className={classes.footer}>
        <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
        Gemaakt door <span className={classes.link}><a href="https://jurrevriesen.nl">Jurre Vriesen</a></span>
    </Typography>
    </footer>
}

export default Footer;