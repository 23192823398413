import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Auth0Context } from "../../react-auth0-spa";
import Dashboard from './dashboard/Dashboard';
import DashboardContext from "./dashboard/DashboardContext";
import ProjectSelector from "./ProjectSelector";
import SignIn from './signIn/SignIn';
import Constants from '../../Constants';
import Axios from 'axios';

class Admin extends Component {

    static contextType = Auth0Context;

    state = {};

    checkProjects = async () => {
        const { projects } = this.context.appUser;

        if(projects.length === 0){
            this.props.history.push(`/register`);
        }

        if (projects.length === 1) {
            this.onSelectProject(projects[0]);
        }
    }

    onSelectProject = (project) => {
        this.props.history.push(`/dashboard/${project}`);
    }

    loadRooms = async () => {
        const { project } = this.props.match.params;
        const result = await Axios.get(`${Constants.apiUrl}${project}/rooms`);
        this.setState({rooms: result.data.map(x => x.name)});
    }

    render() {

        const { project } = this.props.match.params;

        const { isAuthenticated, logout, loading } = this.context;

        if(!isAuthenticated){
            return <SignIn></SignIn>
        }

        if(loading) return 'Laden gebruiker';
        
        const { rooms } = this.state;

        if(!rooms && project) {
            this.loadRooms();
            return 'Laden ruimtes';
        }

        const { projects } = this.context.appUser;

        if(!project) this.checkProjects();

        return (
            <DashboardContext.Provider value={{ project, rooms }}>
                <div>
                    {project ? <Dashboard signOut={() => { logout() }}></Dashboard> : <ProjectSelector projects={projects} onSelectProject={this.onSelectProject}></ProjectSelector>}
                
                </div>
            </DashboardContext.Provider>
        );
    }
}

export default withRouter(Admin);
