import React, { useState, useEffect } from 'react';
import { Typography, Button, TextField } from '@material-ui/core';
import TimeSelector from './TimeSelector';

const Step2 = (props) => {
    const { setVerifier, step, setStep } = props;
    const [errors, setErrors] = useState({});

    useEffect(() => {
        setVerifier({
            verify: () => {
                if(step.description.trim().length === 0){
                    setErrors({...errors, description: true})
                    return false;
                }

                if(step.timeSpan.timeStart > step.timeSpan.timeEnd){
                    setErrors({...errors, timeSpan: true})
                    return false;
                }

                return true;
            }
        });
    }, [step])


    const handle = (name) => (value) => {
        setStep({ ...step, [name]: value });
    }

    const handleChange = (name) => (e) => {
        setStep({ ...step, [name]: e.target.value });
    }

    return <>
        <Typography variant="h6" gutterBottom component="div">Soort evenement</Typography>
        <div>
            <Button color="primary" variant={step.type === "training" ? 'contained' : undefined} onClick={() => handle('type')('training')}>Training</Button>
            <Button color="primary" variant={step.type === "match" ? 'contained' : undefined} onClick={() => handle('type')('match')}>Wedstrijd</Button>
            <Button color="primary" variant={step.type === "event" ? 'contained' : undefined} onClick={() => handle('type')('event')}>Event</Button>
            <Button color="primary" variant={step.type === "recreational" ? 'contained' : undefined} onClick={() => handle('type')('recreational')}>Recreatief</Button>
        </div>

        <TextField
            error={!!errors.description}
            id="outlined-name"
            label="Omschrijving"
            style={{width:'100%', maxWidth:500}}
            margin="normal"
            variant="outlined"
            multiline={true}
            rows={4}
            value={step.description}
            onChange={handleChange('description')}
        />
        <br /><br />
        <Typography variant="h6" gutterBottom component="div">Extra kleedkamers</Typography>
        <TextField
            id="outlined-name"
            label="Aantal kleedkamers"
            margin="normal"
            variant="outlined"
            type="number"
            min={0}
            value={step.amountChangingRooms}
            onChange={handleChange('amountChangingRooms')}
        />
        <br /><br />
        <Typography variant="h6" gutterBottom component="div">Tijdspan</Typography>
        <br/>

        {errors.timeSpan && <div style={{color:'red'}}>
            De starttijd ligt voor de eindtijd.<br/><br/>
        </div>}

        <TimeSelector setTimeSpan={handle('timeSpan')} timeSpan={step.timeSpan} ></TimeSelector>
    </>
}

export default Step2;