import { withStyles } from '@material-ui/core/styles';
import Axios from 'axios';
import React, { Component } from 'react';
import Constants from '../../../../Constants';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AddEventDialog from '../calendarAdmin/dialogs/AddEventDialog';
import moment from 'moment';
import { timeToMoment, dateToMoment } from '../../../../util/momentUtil';
import SendPaymentDialog from './dialogs/SendPaymentDialog';
import DashboardContext from '../DashboardContext';

const styles = {
    card: {
        marginBottom: 20
    },
    acceptButton: {
        backgroundColor: '#4CAF50',
        borderColor: '#4CAF50',
        color: 'white'
    },
    declineButton: {
        backgroundColor: '#F44336',
        borderColor: '#F44336',
        color: 'white'
    },
};

class RequestsAdmin extends Component {

    static contextType = DashboardContext;
   
    state = {
        addEventDialogOpen: false,
        addPaymentDialogOpen: false,
        selectedEvent: null,
        requestedReservations: []
    }

    componentWillMount(){
        this.fetch();
    }

    async fetch() {
        const {project} = this.context;
        const requestedReservations = (await Axios.get(Constants.apiUrl + project + '/requested-reservations')).data;

        this.setState({
            requestedReservations
        });
    }

    accept = reservation => async () => {
        reservation.status = "Geaccepteerd";
        const {project} = this.context;

        await Axios.put(Constants.apiUrl + project + '/requested-reservations/' + reservation.id, reservation);
        this.openEvent(reservation, true);

        this.fetch();
    }

    decline = reservation => async () => {
        reservation.status = "Geweigerd";
        const {project} = this.context;
        await Axios.put(Constants.apiUrl + project + '/requested-reservations/' + reservation.id, reservation);
        this.fetch();
    }

    openEvent = (requested, payment = false) => {

        let {event} = requested;
        event = { ...event };
        event.selectedRooms = event.rooms;

        event.date = dateToMoment(event.date);
        event.timeStart = timeToMoment(event.timeStart);
        event.timeEnd = timeToMoment(event.timeEnd);

        this.setState({
            selectedEvent: event,
            addEventDialogOpen: true,
            requestForPayment: requested,
            handlePayment: payment ? () => {
                this.setState({
                    addPaymentDialogOpen: true,
                });
            } : null
        })
    }

    handleAddEventDialogClose = () => {
        const { handlePayment } = this.state;

        !!handlePayment && handlePayment();

        this.setState({
            handlePayment: null,
            addEventDialogOpen: false,
            selectedEvent: null
        });
    }

    handlePaymentDialogClose = () => {
        this.setState({
            addPaymentDialogOpen: false
        })
    }

    render() {

        const { classes } = this.props;

        const { requestedReservations = [], addEventDialogOpen, selectedEvent,addPaymentDialogOpen, requestForPayment } = this.state;

        requestedReservations.sort((a, b) => {
            if (a.requestedAt._seconds > b.requestedAt._seconds) {
                return -1;
            }
            if (a.requestedAt._seconds < b.requestedAt._seconds) {
                return 1;
            }
            return 0;
        })

        const statusColors = {
            "Geaccepteerd" : "#4CAF50",
            "Geweigerd" : "#F44336"
        }

        return (
            <div>
                <Typography variant="h4" gutterBottom component="h2">
                    Reserveeraanvragen
                </Typography>

                {requestedReservations.length === 0 ? <Typography>Er zijn geen aanvragen binnengekomen.</Typography> : ''}

                {requestedReservations.map(requested =>
                    <Card key={requested.id} className={classes.card}>
                        <CardContent>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                Aangevraagd op {moment((requested.requestedAt._seconds) * 1000).format('LLL')}
                            </Typography>
                            <Typography variant="h5" component="h2">
                                {requested.event.description}
                            </Typography>
                            <Typography component="div">
                                <div><b>Status</b>: <span style={{color : statusColors[requested.status]}}>{requested.status}</span></div>
                                
                                {requested.paymentStatus && <div><b>Betaalstatus</b>: {requested.paymentStatus}</div>}
                                
                                <br />
                                <div><b>Naam</b>: {requested.personal.name}</div>
                                <div><b>Email</b>: {requested.personal.email}</div>
                                <div><b>Telefoon</b>: {requested.personal.phone}</div>
                                <div><b>Opmerking</b>: {requested.personal.note}</div>

                                <br />
                                <div><b>Type organisatie</b>: {requested.event.typeOrganization}</div>
                                <div><b>Vereniging</b>: {requested.event.club}</div>
                                <div><b>Ruimtes</b>: {requested.event.rooms.join(', ')}</div>
                                <div><b>Datum</b>: {requested.event.date} {requested.event.allDay ? 'hele dag' : `${requested.event.timeStart} - ${requested.event.timeEnd}`} </div>
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="small" variant="outlined" color="primary" onClick={this.accept(requested)} className={classes.acceptButton}>
                                Accepteer & Plaats
                            </Button>
                            <Button size="small" variant="outlined" color="primary" onClick={this.decline(requested)} className={classes.declineButton}>
                                Weiger
                            </Button>
                        </CardActions>
                    </Card>
                )}

                <AddEventDialog selectedevent={selectedEvent} open={addEventDialogOpen} onClose={this.handleAddEventDialogClose}></AddEventDialog>
                <SendPaymentDialog requestforpayment={requestForPayment} open={addPaymentDialogOpen} onClose={this.handlePaymentDialogClose}></SendPaymentDialog>

            </div>
        );
    }
}

export default withStyles(styles)(RequestsAdmin);
