import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

const styles = {
    textField : {
        maxWidth: 400,
        width: '100%'
    }
};


class Personal extends React.Component {

    onPersonalUpdate = (personal) => {
        this.props.onPersonalUpdate && this.props.onPersonalUpdate(personal)
    }

    handleChange = name => evt => {
        let {personal = {}} = this.props;

        personal[name] = evt.target.value;

        this.onPersonalUpdate(personal);
    }

    handleChecked = name => evt => {
        let {personal = {}} = this.props;

        personal[name] = evt.target.checked;

        this.onPersonalUpdate(personal);
    };

    static getErrors(personal){
        const errors = {};

        if(personal.name.length === 0){
            errors.name = 'Dit veld is verplicht';
        }

        if(personal.email.length === 0){
            errors.email = 'Dit veld is verplicht';
        }

        if(personal.phone.length === 0){
            errors.phone = 'Dit veld is verplicht';
        }

        return errors;
    }

    render() {
        const {classes, showErrors} = this.props;

        const {personal = {}} = this.props;

        let errors = {};
        if(showErrors){
            errors = Personal.getErrors(personal);
        }

        return (
            <div>
                <div>
                        <TextField
                            id="outlined-name"
                            label="Naam (verplicht)"
                            error={errors.name !== undefined}
                            helperText={errors.name || ''}
                            value={personal.name}
                            onChange={this.handleChange('name')}
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                        />
                    </div>
                    <div>
                        <TextField
                            id="outlined-name"
                            label="Email (verplicht)"
                            error={errors.email !== undefined}
                            helperText={errors.email || ''}
                            value={personal.email}
                            onChange={this.handleChange('email')}
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                        />
                    </div>
                    <div>
                        <TextField
                            id="outlined-name"
                            label="Telefoonnummer (verplicht)"
                            error={errors.phone !== undefined}
                            helperText={errors.phone || ''}
                            value={personal.phone}
                            onChange={this.handleChange('phone')}
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                        />
                    </div>
                    <div>
                        <TextField
                            id="outlined-name"
                            label="Opmerking (optioneel)"
                            value={personal.note}
                            onChange={this.handleChange('note')}
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            multiline={true}
                            rows={4}
                        />
                    </div>

            </div>
        );
    }
}

export default withStyles(styles)(Personal);
