import moment from 'moment';

export const timeToMoment = time => {
    const splitted = time.split(":");

    if (splitted.length === 2) {
        return moment().hours(parseInt(splitted[0])).minutes(parseInt(splitted[1]));
    }

    return moment();
}

export const dateToMoment = date => {
    const splitted = date.split("-");

    if (splitted.length === 3) {
        return moment().year(parseInt(splitted[0])).month(parseInt(splitted[1] - 1)).date(parseInt(splitted[2]));
    }

    return moment();
}

export const roundToQuarter = (date) => {
    var m = (Math.round(date.minute()/15) * 15)

    if(m === 60){
        date.hours(date.hours() + 1);
    }

    return date.minute(m % 60).clone();
}