import React, { useState, useEffect } from 'react';

import Map from '../../form/map/Map';
import Checkboxes from '../../form/Checkboxes';

import { filterReservationsInTimeSpanWithRooms, fetchAndFormatAllReservations } from '../../../util/reservationUtil';
import Constants from '../../../Constants';


const Step3 = (props) => {

    const {step2} = props;

    const [reservedRooms, setReservedRooms] = useState([]);

    useEffect(() => {

        const fetch = async () => {
            const reservations = filterReservationsInTimeSpanWithRooms(await fetchAndFormatAllReservations('sportparkzuid'), step2.timeSpan.timeStart.unix(), step2.timeSpan.timeEnd.unix(), Constants.rooms);

            let reserved = [];

            reservations.forEach((reservation) => {
                reservation.rooms.forEach(room => reserved.push(room))
            });
            
            setReservedRooms(reserved);
        }

        fetch();
    }, []);



    const { step, setStep, setVerifier } = props;

    useEffect(() => {
        setVerifier({
            verify: () => {
                if (step.rooms.length === 0) {
                    alert('Je moet ruimtes selecteren');
                    return false;
                }

                return true;
            }
        });
    }, [step])

    const { rooms } = step;

    const onSelectedUpdate = (rooms) => {
        setStep({ ...step, rooms: [...rooms] })
    }

    return <>
        <Map onSelectedUpdate={onSelectedUpdate} selected={rooms}></Map>
        <Checkboxes onSelectedUpdate={onSelectedUpdate} selected={rooms} reserved={reservedRooms}></Checkboxes>
    </>

}

export default Step3;