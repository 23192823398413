import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Paper, Switch, Tab, Tabs, TextField } from '@material-ui/core';
import { InlineDatePicker, InlineTimePicker } from "material-ui-pickers";
import React, { useState } from 'react';
import { roundToQuarter } from '../../../util/momentUtil';
import moment from "moment";

const TimeSelector = (props) => {
    const errors = {};
    const classes = {};

    const {setTimeSpan, timeSpan} = props;

    const [currentTab, setCurrentTab] = useState(0);

    const {morning, noon, evening } = timeSpan

    const handleDateChange = name => (date) => {
        date = roundToQuarter(date)

        if (name === "timeStart" || name === "timeEnd") {
            timeSpan.morning = false;
            timeSpan.noon = false;
            timeSpan.evening = false;
        }

        timeSpan[name] = date;

        setTimeSpan({...timeSpan});
     }

    const handleTabChange = (event, value) => {
        setCurrentTab(value)
    }

    const handleChecked = name => evt => {
        timeSpan[name] = evt.target.checked;

        if (name === "morning" || name === "noon" || name === "evening") {
            if (timeSpan.morning) {
                timeSpan.timeStart = timeSpan.timeStart.clone().hours(6).minutes(0);
                timeSpan.timeEnd = timeSpan.timeStart.clone().hours(12).minutes(0);
            }

            if (timeSpan.noon) {
                !timeSpan.morning && (timeSpan.timeStart = timeSpan.timeStart.clone().hours(12).minutes(0));
                timeSpan.timeEnd = timeSpan.timeStart.clone().hours(18).minutes(0);
            }

            if (timeSpan.evening) {
                !timeSpan.noon && (timeSpan.timeStart = timeSpan.timeStart.clone().hours(18).minutes(0));
                timeSpan.timeEnd = timeSpan.timeStart.clone().hours(23).minutes(59);
            }
        }

        setTimeSpan({...timeSpan});
    };

    return <div>
        <InlineDatePicker
            label="Datum (verplicht)"
            variant="outlined"
            error={errors.date !== undefined ? true : undefined}
            helperText={errors.date || ''}
            value={timeSpan.date}
            onChange={handleDateChange('date')}
            minDate={moment().add('1 day')}
        >
        </InlineDatePicker>
        <br />

        <Paper className={classes.root} style={{marginTop:18}}>
            <Tabs
                value={currentTab}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                
            >
                <Tab label="Specifieke tijd" />
                <Tab label="Per dagdeel" />
            </Tabs>
        </Paper>

        {currentTab === 0 ? <div>
            <br />
            <FormControlLabel
                control={
                    <Switch
                        checked={timeSpan.allDay}
                        onChange={handleChecked('allDay')}
                        value="checkedA"
                    />
                }
                label="Duurt de hele dag"
            />
            <br /><br />

            {!timeSpan.allDay ? <div>
                <InlineTimePicker
                    ampm={false}
                    keyboard
                    mask={[/\d/, /\d/, ":", /\d/, /\d/]}
                    variant="outlined"
                    label="Van"
                    error={errors.timeStart !== undefined ? true : undefined}
                    helperText={errors.timeStart || ''}
                    onChange={handleDateChange('timeStart')}
                    value={timeSpan.timeStart}
                    TextFieldComponent={(props) => {
                        return <TextField {...props}></TextField>
                    }
                    }
                />
                <br /><br />
                <InlineTimePicker
                    ampm={false}
                    keyboard
                    mask={[/\d/, /\d/, ":", /\d/, /\d/]}
                    variant="outlined"
                    label="Tot"
                    error={errors.timeEnd !== undefined ? true : undefined}
                    helperText={errors.timeEnd || ''}
                    onChange={handleDateChange('timeEnd')}
                    value={timeSpan.timeEnd}
                />
                <br /><br />
            </div> : ''}
        </div> : <div>
                <br />
                <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel component="legend">Kies de dagedelen</FormLabel>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox checked={morning} disabled={evening && !noon} onChange={handleChecked('morning')} value="morning" />}
                            label="Ochtend 06:00 - 12:00"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={noon} disabled={morning && evening} onChange={handleChecked('noon')} value="noon" />}
                            label="Middag 12:00 - 18:00"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox checked={evening} disabled={morning && !noon} onChange={handleChecked('evening')} value="evening" />
                            }
                            label="Avond 06:00 - 00:00"
                        />
                    </FormGroup>
                    <FormHelperText>De dagdelen moeten elkaar opvolgen</FormHelperText>
                    <br />
                </FormControl>

            </div>}

    </div>
}

export default TimeSelector;