import React, { useState } from 'react';
import Select from '@material-ui/core/Select';
import { Button, CssBaseline, Paper, Typography } from "@material-ui/core";
import withStyles from '@material-ui/core/styles/withStyles';
import Header from '../common/Header';

const styles = theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        padding: theme.spacing(1),
        [theme.breakpoints.up(400 + theme.spacing(6))]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(3, 2)
    },
    select: {
        width: '100%',
        margin: theme.spacing(0, 0, 2, 0)
    }
});

const ProjectSelector = (props) => {

    const { projects = [], classes } = props;
    const [project, setProject] = useState("");

    const submit = () => {
        props.onSelectProject(project);
    };

    return <>
        <Header></Header>
        <main className={classes.main}>
            <CssBaseline />
            <Paper className={classes.paper}>
                <Typography variant="h6">Kies je project</Typography>
                <Select className={classes.select} native value={project} onChange={(e) => setProject(e.target.value)}>
                    <option value=""></option>
                    {projects.map(project => <option value={project} key={project}>{project}</option>)}
                </Select>
                <Button disabled={!project} onClick={submit} variant="contained" color="primary">Selecteer</Button>
            </Paper>

        </main>
    </>;
};

export default withStyles(styles)(ProjectSelector);