import { Checkbox, Chip, FormControl, MenuItem, OutlinedInput, Select, TextField, AppBar, Tabs, Tab } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from "@material-ui/core/InputLabel";
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import ReactDOM from "react-dom";
import Axios from 'axios';
import Constants from '../../../../../Constants';
import DeleteIcon from '@material-ui/icons/Delete';
import MultipleDateSelect from './MultipleDateSelect';
import moment from 'moment';
import DashboardContext from '../../DashboardContext'

import { InlineDatePicker, InlineTimePicker } from 'material-ui-pickers';
const styles = theme => ({
    textField: {
        width: '100%',
        labelWidth: 0
    },
});

class AddRecurringEventDialog extends React.Component {

    static contextType = DashboardContext;

    emptyEvent = {
        description: '',
        club: '',
        day: 0,
        from: moment(),
        to: moment(),
        timeStart: moment(),
        timeEnd: moment(),
        rooms: [],
        except: []
    };

    constructor(props) {
        super(props);
        
        this.state = {
            event: { ...this.emptyEvent },
            labelWidth: 100,
            currentTab: 0
        }

        this.UNSAFE_componentWillReceiveProps(props);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.state.event = nextProps.selectedevent || this.emptyEvent; //eslint-disable-line
    }

    inEditModus = () => {
        return this.state.event.id !== null && this.state.event.id !== undefined;
    }

    save = async () => {
        const event = { ...this.state.event };

        const {project} = this.context;

        event.from = event.from.toDate();
        event.to = event.to.toDate();

        event.timeStart = event.timeStart.format("HH:mm");
        event.timeEnd = event.timeEnd.format("HH:mm");

        event.except = event.except.map(e => e.toDate())

        try {
            if (this.inEditModus()) {
                await Axios.put(Constants.apiUrl + project + '/recurring-reservations/' + event.id, event);
            } else {
                await Axios.post(Constants.apiUrl + project + '/recurring-reservations', event);
            }

            this.handleClose();
        } catch (err) {
            alert('Het evenement kon niet opgeslagen worden.')
        }

    };

    delete = async () => {
        const {project} = this.context;

        try {
            await Axios.delete(Constants.apiUrl + project + '/recurring-reservations/' + this.state.event.id);
            this.handleClose();
        } catch (err) {
            alert('Het evenement kon niet opgeslagen worden.')
        }
    }

    handleClose = () => {
        this.props.onClose();
    };

    handleListItemClick = value => {
        this.props.onClose(value);
    };

    handleChange = name => event => {
        this.setState({
            event: {
                ...this.state.event,
                [name]: event.target.value,
            }
        });
    };

    handleDateChange = name => (date, value) => {
        this.setState({
            event: {
                ...this.state.event,
                [name]: date
            }
        });
    }

    componentDidMount() {
        if (this.InputLabelRef) {
            this.setState({
                labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth
            });
        }
    }

    handleTabChange = (event, value) => {
        this.setState({ currentTab: value });
    };

    handleExceptChange = (dates) => {
        this.setState({
            event: {
                ...this.state.event,
                except: dates
            }
        });
    }

    render() {
        const { classes, onClose, ...other } = this.props;

        const { currentTab } = this.state;

        const {rooms} = this.context;

        return (
            <Dialog onClose={this.handleClose} aria-labelledby="simple-dialog-title" {...other}>
                <DialogTitle id="simple-dialog-title">

                    Herhalend evenement {this.inEditModus() ? 'wijzigen' : 'toevoegen'}

                    {
                        this.inEditModus ? (<Button style={{ float: 'right' }} onClick={this.delete} color="secondary"><DeleteIcon></DeleteIcon></Button>) : ''
                    }

                </DialogTitle>


                <div style={{ padding: 15, maxWidth: 500, marginTop: -30, width: '100vw' }}>
                    <AppBar position="static">
                        <Tabs value={currentTab} onChange={this.handleTabChange}>
                            <Tab label="Gegevens" />
                            <Tab label="Afgelastingen" />
                        </Tabs>
                    </AppBar>
                    {currentTab === 0 ? (<div>

                        <div>
                            <TextField
                                id="outlined-name"
                                label="Vereniging"
                                value={this.state.event.club}
                                onChange={this.handleChange('club')}
                                className={classes.textField}
                                margin="normal"
                                variant="outlined"
                            />
                        </div>
                        <div>
                            <TextField
                                id="outlined-name"
                                label="Omschrijving"
                                value={this.state.event.description}
                                onChange={this.handleChange('description')}
                                className={classes.textField}
                                margin="normal"
                                variant="outlined"
                            />
                        </div>
                        <br />
                        <div>

                            <InlineDatePicker
                                label="Periode van"
                                className={classes.textField}
                                variant="outlined"
                                value={this.state.event.from}
                                onChange={this.handleDateChange('from')} >
                            </InlineDatePicker>


                            <br /><br />

                            <InlineDatePicker
                                label="Periode tot"
                                className={classes.textField}
                                variant="outlined"
                                value={this.state.event.to}
                                onChange={this.handleDateChange('to')} >
                            </InlineDatePicker>

                            <br /><br />

                            <Select
                                id="day"
                                label="dag"
                                value={this.state.event.day}
                                onChange={this.handleChange('day')}
                                className={classes.textField}
                                variant="outlined"
                                input={
                                    <OutlinedInput
                                        labelWidth={this.state.labelWidth}
                                        name="age"
                                        id="outlined-age-simple"
                                    />
                                }
                            >
                                <MenuItem value={0}>Zondag</MenuItem>
                                <MenuItem value={1}>Maandag</MenuItem>
                                <MenuItem value={2}>Dinsdag</MenuItem>
                                <MenuItem value={3}>Woensdag</MenuItem>
                                <MenuItem value={4}>Donderdag</MenuItem>
                                <MenuItem value={5}>Vrijdag</MenuItem>
                                <MenuItem value={6}>Zaterdag</MenuItem>
                            </Select>

                            <br /><br />

                            <InlineTimePicker
                                ampm={false}
                                keyboard
                                label="Van"
                                type="time"
                                value={this.state.event.timeStart}
                                onChange={this.handleDateChange('timeStart')}
                                variant="outlined"
                            />

                            &nbsp;
                            <InlineTimePicker
                                ampm={false}
                                keyboard
                                label="Van"
                                type="time"
                                value={this.state.event.timeEnd}
                                onChange={this.handleDateChange('timeEnd')}
                                variant="outlined"
                            />
                        </div>

                        <br />

                        <div>
                            <FormControl variant="outlined" className={classes.textField}>
                                <InputLabel
                                    ref={ref => {
                                        this.InputLabelRef = ref;
                                    }}
                                    className={classes.textField}
                                    htmlFor="select-multiple-chip"
                                >
                                    Ruimtes
                            </InputLabel>
                                <Select
                                    multiple
                                    variant="outlined"
                                    value={this.state.event.rooms}
                                    onChange={this.handleChange('rooms')}
                                    className={classes.textField}
                                    input={<OutlinedInput labelWidth={this.state.labelWidth} id="select-multiple-chip" />}
                                    renderValue={selected => (
                                        <div className={classes.chips}>
                                            {selected.map(value => (
                                                <Chip key={value} label={value} className={classes.chip} />
                                            ))}
                                        </div>
                                    )}
                                >
                                    {rooms.map(name => (
                                        <MenuItem key={name} value={name}>
                                            <Checkbox checked={this.state.event.rooms.indexOf(name) > -1} />
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>

                        <br />

                    </div>) : (<div>
                        <MultipleDateSelect onDatesSet={this.handleExceptChange} values={this.state.event.except}></MultipleDateSelect>
                        <br />
                    </div>)}

                    <Button variant="contained" color="primary" onClick={this.save}>Opslaan</Button>

                    <Button style={{ float: "right" }} onClick={this.handleClose}>Annuleren</Button>
                </div>

            </Dialog>
        );
    }
}

export default withStyles(styles)(AddRecurringEventDialog);
